import { useState } from 'react';
import { IonLoading, IonToast, IonHeader, IonMenuToggle, IonAlert, IonTitle, IonToolbar, IonButton, IonIcon, IonSearchbar, IonContent, IonMenu, IonMenuButton, IonGrid, IonRow } from '@ionic/react';
import { notifications, people, logOutOutline, playBackOutline, closeOutline, refreshOutline, arrowUndoOutline, settingsOutline, cardOutline, mailOutline, keyOutline } from 'ionicons/icons';
import DefaultRsvpModal from '../components/Default-RSVP-Modal/DefaultRsvpModal';
import GetCommunity from '../components/Get-Community/GetCommunity';
import EditProfileModal from '../components/Edit-Profile-Modal/EditProfileModal';
import SubmitFeedback from '../components/Submit-Feedback/SubmitFeedback';

const AppHeader = (props: any) => {
    let csv = localStorage.getItem('communitiesCSV');
    let autoOpenCommunitySelection = csv?.indexOf(',') === -1 ? false : true;
    let communityOpened = localStorage.getItem('currentCommunityID')?.length === 0 ? false : true;
    if (communityOpened) {
        autoOpenCommunitySelection = false;
    }

    const [showAlert, setShowAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [toastDuration, setToastDuration] = useState(2000);
    const [isOpen, setIsOpen] = useState(autoOpenCommunitySelection);
    const [openRewind, setOpenRewind] = useState(false);
    const [openDefaultRsvp, setOpenDefaultRsvp] = useState(false);
    const [getDefaults, setGetDefaults] = useState(false);
    const [rsvpInfo, setRsvpInfo] = useState({
        'Men': 0,
        'Women': 0,
        'Boys': 0,
        'Girls': 0
    });

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const [showProfileSettings, setShowProfileSettings] = useState(false);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [city, setCity] = useState();
    const [stateid, setStateid] = useState();
    const [countryid, setCountryid] = useState();
    const [zip, setZip] = useState();
    const [phone, setPhone] = useState();
    const [wphone, setWphone] = useState();
    const [email, setEmail] = useState();
    const [email2, setEmail2] = useState();
    const [prefcom, setPrefcom] = useState();

    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState("");

    async function GetDefaults() {
        const url = `https://wennect.info/api/reqUserInfo.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            setRsvpInfo({
                'Men': data.V1CLK_RSVP_MEN === null ? 0 : data.V1CLK_RSVP_MEN,
                'Women': data.V1CLK_RSVP_WOMEN === null ? 0 : data.V1CLK_RSVP_WOMEN,
                'Boys': data.V1CLK_RSVP_BOYS === null ? 0 : data.V1CLK_RSVP_BOYS,
                'Girls': data.V1CLK_RSVP_GIRLS === null ? 0 : data.V1CLK_RSVP_GIRLS
            })

            setOpenDefaultRsvp(true);
            setGetDefaults(true);
        } catch (e: any) {
            setShowToast(true);
            setToastMessage(e)
            setToastType("danger");
        }
    }

    function openModal() {
        setIsOpen(true);
    }

    async function logout() {
        setLoading(true);
        setLoadMessage("Logging out...");
        await fetch(`https://wennect.info/api/logout.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}`);

        window.localStorage.clear();
        window.sessionStorage.clear();
        props.setIsLoggedIn(false);
        setLoading(false);
    }

    async function handleConfirm(old: any, pass: any, confirm: any) {
        const url = `https://wennect.info/api/changepwd.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&chgPwdObj={"pwd":"${old}","npwd":"${pass}","cnpwd":"${confirm}"}`;

        if (old === pass && pass === confirm) {
            const str = "Come on man! Don't waste my time! I'm a very hard-working website!";
            setToastMessage(str);
            setToastType("medium");
            setToastDuration(str.length * 100)
        } else {
            setLoading(true);
            setLoadMessage("Changing Password...");
            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                    setToastMessage(data.DESCRIPTION);
                    setToastType("success");
                    setToastDuration(2000)
                    setShowToast(true);
                    setTimeout(() => {
                        logout();
                    }, 2000);
                } else if (data.constructor === Object && data.DESCRIPTION) {
                    setToastMessage(data.DESCRIPTION);
                    setToastType("danger");
                    setToastDuration(5000)
                } else {
                    setToastMessage(data);
                    setToastType("danger");
                    setToastDuration(5000)
                }
            } catch (e: any) {
                setToastMessage(e.message);
                setToastType("danger");
                setToastDuration(3000);
            }
            setLoading(false);
        } setShowToast(true);
    }

    async function showEditProfileModalFunction() {
        const url = `https://wennect.info/api/reqUserInfo.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}`;
        console.log(url);
        setLoading(true);
        setLoadMessage("Opening Modal...");

        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            console.log(data.e_mail2);

            if (data.constructor === Object && data.username && data.username === localStorage.getItem('username')) {
                setFname(data.f_name);
                setLname(data.l_name);
                setAddress1(data.ad1);
                setAddress2(data.ad2);
                setCity(data.city);
                setStateid(data.stateid);
                setCountryid(data.countryid);
                setZip(data.zp);
                setPhone(data.cphone);
                setWphone(data.wphone);
                setEmail(data.e_mail);
                setEmail2(data.e_mail2);
                setPrefcom(data.prefcom);
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setToastMessage(data.DESCRIPTION);
                setToastType("danger");
                setToastDuration(5000);
                setShowToast(true);
            } else {
                setToastMessage(data);
                setToastType("danger");
                setToastDuration(5000);
                setShowToast(true);
            }
        } catch (e: any) {
            setToastMessage('error: ' + e.message);
            setToastType("danger");
            setToastDuration(3000);
            setShowToast(true);
        }

        setShowEditProfileModal(true);
        setLoading(false);
    }

    return (
        <>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            {props.showMenu && <IonMenu contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Menu Content</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid>
                        <IonRow>
                            <IonButton fill="clear" onClick={() => { setShowProfileSettings(!showProfileSettings) }}>
                                <IonIcon icon={settingsOutline}></IonIcon>
                                &nbsp;Manage Profile
                            </IonButton>
                            {showProfileSettings &&
                                <IonGrid>
                                    <IonRow>
                                        &emsp;&emsp;
                                        <IonMenuToggle>
                                            <IonButton fill="clear" onClick={showEditProfileModalFunction}>
                                                <IonIcon icon={cardOutline}></IonIcon>
                                                &nbsp;Edit Profile
                                            </IonButton>
                                        </IonMenuToggle>
                                    </IonRow>
                                    <IonRow>
                                        &emsp;&emsp;
                                        <IonMenuToggle>
                                            <IonButton fill="clear" onClick={GetDefaults}>
                                                <IonIcon icon={arrowUndoOutline}></IonIcon>
                                                &nbsp;Default RSVP Counts
                                            </IonButton>
                                        </IonMenuToggle>
                                    </IonRow>
                                    <IonRow>
                                        &emsp;&emsp;
                                        <IonMenuToggle>
                                            <IonButton fill="clear" onClick={() => setShowAlert(true)}>
                                                <IonIcon icon={keyOutline}></IonIcon>
                                                &nbsp;Change Password
                                            </IonButton>
                                        </IonMenuToggle>
                                    </IonRow>
                                </IonGrid>
                            }
                        </IonRow>
                        {/* <IonRow>
                            {
                                // TODO: ADD A PROPER FUNCTION TO THIS BUTTON 
                                // API CALL = https://wennect.info/api/submitFeedback.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&submitFeedbackObj={"feedbackText":"${encodeURI(escape(jsonData.feedbackText))}","subject":"${encodeURI(escape(jsonData.subject))}"}
                            }
                            <IonMenuToggle>
                                <a href="mailto:support@wennect.info?subject=Support%20Request">
                                    <IonButton fill="clear" onClick={() => setShowFeedbackModal(true)}>
                                        <IonIcon icon={mailOutline}></IonIcon>
                                        &nbsp;Submit Feedback
                                    </IonButton>
                                </a>
                            </IonMenuToggle>
                        </IonRow> */}
                        <IonMenuToggle>
                                <a href="mailto:support@wennect.info?subject=Support%20Request">
                                    <IonButton fill="clear">
                                        <IonIcon icon={mailOutline}></IonIcon>
                                        &nbsp;Submit Feedback
                                    </IonButton>
                                </a>
                            </IonMenuToggle>
                        <IonRow>
                            <IonButton fill="clear" onClick={logout}>
                                <IonIcon icon={logOutOutline}></IonIcon>
                                &nbsp;Logout
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonMenu>}
            <IonHeader id="main-content">
                <IonToolbar>
                    {props.showMenuButton && <IonMenuButton onClick={() => setOpenRewind(false)} slot="start" />}
                    <IonTitle>{props.title}</IonTitle>
                    <IonButton fill="clear" onClick={() => { setOpenRewind(false) }} slot="end">
                        <IonIcon slot="icon-only" icon={notifications}></IonIcon>
                    </IonButton>
                    <IonButton fill="clear" id="main-content" onClick={() => { openModal(); setOpenRewind(false) }} slot="end">
                        <IonIcon slot="icon-only" icon={people}></IonIcon>
                    </IonButton>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar debounce={800} autocomplete='off' onIonChange={(e: any) => { sessionStorage.setItem('searchFor', e.detail.value.trim()); props.setRefresh(true) }} animated={true} showClearButton="always" placeholder={"Search " + props.searchFor} ></IonSearchbar>
                    {(window.localStorage.getItem('isCommunityAdmin') === 'true' || window.localStorage.getItem('iAmEventsAdmin')) && <IonButton fill="clear" color="medium" size="small" onClick={() => { setOpenRewind(!openRewind) }} slot="end">
                        {!openRewind && <IonIcon slot="icon-only" icon={playBackOutline} />}
                        {openRewind && <IonIcon slot="icon-only" icon={closeOutline} />}
                    </IonButton>}
                </IonToolbar>
                {openRewind &&
                    <IonToolbar>
                        <IonGrid>
                            <IonRow>
                                <h5>Show Past Events:</h5>
                            </IonRow>
                            <IonRow class="ion-justify-content-around">
                                <IonButton fill="clear" onClick={() => { setOpenRewind(false); window.sessionStorage.removeItem('fetching'); window.sessionStorage.removeItem('eventData'); props.checkFetching('') }} >
                                    <IonIcon size="small" slot="icon-only" icon={refreshOutline} />
                                </IonButton>
                                <IonButton fill="clear" onClick={() => { setOpenRewind(false); window.sessionStorage.removeItem('fetching'); window.sessionStorage.removeItem('eventData'); props.checkFetching('&eventRange=3MONTHS') }} >
                                    3 Months
                                </IonButton>
                                <IonButton fill="clear" onClick={() => { setOpenRewind(false); window.sessionStorage.removeItem('fetching'); window.sessionStorage.removeItem('eventData'); props.checkFetching('&eventRange=1YEAR') }} >
                                    1 Year
                                </IonButton>
                                <IonButton fill="clear" onClick={() => { setOpenRewind(false); window.sessionStorage.removeItem('fetching'); window.sessionStorage.removeItem('eventData'); props.checkFetching('&eventRange=2YEARS') }} >
                                    2 Years
                                </IonButton>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                }
            </IonHeader>
            <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={toastDuration} />
            <DefaultRsvpModal open={openDefaultRsvp} setOpen={setOpenDefaultRsvp} showToast={showToast} setShowToast={setShowToast} toastType={toastType} setToastType={setToastType} toastMessage={toastMessage} setToastMessage={setToastMessage} rsvpInfo={rsvpInfo} setRsvpInfo={setRsvpInfo} getDefaults={getDefaults} setGetDefaults={setGetDefaults} />
            <GetCommunity setRefresh={props.setRefresh} checkFetching={props.checkFetching} isOpen={isOpen} setIsOpen={setIsOpen} />
            <EditProfileModal showModal={showEditProfileModal} setShowModal={setShowEditProfileModal} fname={fname} setFname={setFname} lname={lname} setLname={setLname} address1={address1} setAddress1={setAddress1} address2={address2} setAddress2={setAddress2} city={city} setCity={setCity} stateid={stateid} setStateid={setStateid} countryid={countryid} setCountryid={setCountryid} zip={zip} setZip={setZip} phone={phone} setPhone={setPhone} wphone={wphone} setWphone={setWphone} email={email} setEmail={setEmail} email2={email2} setEmail2={setEmail2} prefcom={prefcom} setPrefcom={setPrefcom} />
            <SubmitFeedback showModal={showFeedbackModal} setShowModal={setShowFeedbackModal} />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Change Password'}
                inputs={[
                    {
                        name: 'oldpwd',
                        type: 'password',
                        placeholder: 'Current Password',
                        attributes: {
                            minlength: 8
                        }
                    },
                    {
                        name: 'input',
                        type: 'password',
                        placeholder: 'New Password',
                        attributes: {
                            minlength: 8
                        }
                    },
                    {
                        name: 'confirm',
                        type: 'password',
                        placeholder: 'Confirm New Password',
                        attributes: {
                            minlength: 8
                        }
                    }
                ]}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowAlert(false)
                    },
                    {
                        text: 'Confirm',
                        handler: (alertData) => { handleConfirm(alertData.oldpwd, alertData.input, alertData.confirm) }
                    }
                ]}
            />
        </>
    );
}

AppHeader.defaultProps = {
    showRewind: false,
    searchFor: '',
    showMenu: false,
    showMenuButton: false,
}

export default AppHeader;