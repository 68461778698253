import { useState } from 'react';

import './Events.css';
import { IonLoading, IonText, IonAlert, useIonAlert, IonToast, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { notificationsOutline, documentTextOutline, timeOutline, locationOutline, warningOutline, arrowUndoOutline, checkmarkDoneOutline, pencilOutline, copyOutline, eyeOutline, trashOutline, ellipsisVerticalOutline, closeOutline, add } from 'ionicons/icons';

import EventRsvpModal from './EventRsvpModal';
import EventCheckInModal from './EventCheckInModal';
import EventAddGuestsModal from './EventAddGuestsModal';
import CreateEvent from './CreateEvent';
import Report from './Report/Report';

import { Geolocation } from '@capacitor/geolocation';

const getCurrentPosition = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    
    return {
        long: JSON.stringify(coordinates.coords.longitude),
        lat: JSON.stringify(coordinates.coords.latitude)
    }
};

const Event = (props: any) => {
    const [isRsvpOpen, setIsRsvpOpen] = useState(false);
    const [isCheckInOpen, setIsCheckInOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const EventStartDate = new Date(`${props.event.event_date_start}T${props.event.event_time_start}`);
    const EventEndDate = new Date(`${props.event.event_date_end}T${props.event.event_time_end}`);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage]: any = useState("");
    const [toastType, setToastType]: any = useState("");
    const [openSelections, setOpenSelections] = useState(false);
    const [presentAlert] = useIonAlert();
    const [showAlert, setShowAlert] = useState(false);
    const [showDelAlert, setShowDelAlert] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const date = EventStartDate;
    const weekday = date.toLocaleString('en-US', { weekday: 'short' });
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear().toString().substring(-2);
    const formattedDate = `${weekday} ${day}-${month}-${year}`;

    const dateEnd = EventEndDate;
    const weekdayEnd = dateEnd.toLocaleString('en-US', { weekday: 'short' });
    const dayEnd = dateEnd.getDate();
    const monthEnd = dateEnd.toLocaleString('en-US', { month: 'short' });
    const yearEnd = dateEnd.getFullYear().toString().substring(-2);
    const formattedDateEnd = `${weekdayEnd} ${dayEnd}-${monthEnd}-${yearEnd}`;

    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');

    const [checkInLoading, setCheckInLoading] = useState(false);
    const [checkInLoadMessage, setCheckInLoadMessage] = useState('');

    const [adminCheckIn, setAdminCheckIn] = useState(false);

    const [showReport, setShowReport] = useState(false);
    const [attending, setAttending] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [thaalStats, setThaalStats] = useState([]);
    const [reportData, setReportData] = useState([]);

    const [editingEvent, setEditingEvent] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    const [addGuestsModal, setAddGuestsModal] = useState(false);

    function searchCheck(): boolean {
        console.log(sessionStorage.getItem('searchFor'));
        if (sessionStorage.getItem('searchFor') === null || sessionStorage.getItem('searchFor') === "") {
            return true;
        }

        let searchFor = sessionStorage.getItem('searchFor')?.toLowerCase();
        console.log(searchFor);
        console.log(props.event.event_title.toLowerCase());
        console.log(props.event.event_title.toLowerCase().indexOf(searchFor));
        if (
            props.event.event_title.toLowerCase().indexOf(searchFor) > -1
            || props.event.event_desc.toLowerCase().indexOf(searchFor) > -1
            || props.event.chkInWarnMsg.toLowerCase().indexOf(searchFor) > -1
            || props.event.location.toLowerCase().indexOf(searchFor) > -1
            || props.event.event_loc_address.toLowerCase().indexOf(searchFor) > -1
        ) {
            console.log('search matched for ' + searchFor);
            console.log(props.event);
            return true;
        }
        console.log('search did not match with ' + searchFor);
        return false;
    }

    async function checkFamMap() {
        let geoPos = {
            long: '',
            lat: '',
        };

        if (props.event.chkInGeoProxReq === 'true') {
            geoPos = await getCurrentPosition();
        }
        console.log(localStorage.getItem('family_info'));
        if (window.localStorage.getItem('family_info') === 'NOFAMMAPINFO') {
            try {
                setLoading(true);
                setLoadMessage('Checking In...')
                const url = `https://wennect.info/apps/events/doEventCheckIn.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}","lat":"${geoPos.lat}","long":"${geoPos.long}","location":"undefined"}&doaddresslookup`;

                const response = await fetch(url);
                const JSONdata = await response.json();
                // console.log(JSONdata);
                if (JSONdata.constructor === Object && JSONdata.CODE && JSONdata.CODE === 'SUCCESS') {
                    props.setRefresh(true);
                    setToastMessage(JSONdata.DESCRIPTION);
                    setToastType('success');
                } else if (JSONdata.constructor === Object && JSONdata.DESCRIPTION) {
                    setToastMessage(JSONdata.DESCRIPTION);
                    setToastType('danger');
                } else {
                    setToastMessage(JSONdata);
                    setToastType('danger');
                }
            } catch (e: any) {
                setToastMessage(e.message);
                setToastType('danger');
            }

            setShowToast(true);
        } else {
            setAdminCheckIn(false);
            setIsCheckInOpen(true);
        }
        setLoading(false);
    }

    function sendWIPMessage() {
        setToastMessage('WORK IN PROGRESS');
        setToastType('');
        setShowToast(true);
    }

    async function sendReminder() {
        // console.log('WIP')
        // setToastMessage('WORK IN PROGRESS');
        // setToastType('');
        // setShowToast(true);
        const url = `https://wennect.info/adm/apps/events/sendEventReminder.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&eventReminderObj={"CommunityID":"${localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}"}`;

        try {
            setLoading(true);
            setLoadMessage('Sending Reminder...')
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                setToastMessage(data.DESCRIPTION);
                setToastType("success");
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setToastMessage(data.DESCRIPTION);
                setToastType("danger");
            } else {
                setToastMessage(data);
                setToastType("danger");
            }
        } catch (e: any) {
            setToastMessage(e.message)
            setToastType("danger");
        }
        setLoading(false);
        setShowToast(true);
    }

    function confirmReminder() {
        presentAlert({
            header: 'Confirm Reminder!',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        setToastMessage('Canceled');
                        setToastType('danger');
                        setShowToast(true);
                    },
                },
                {
                    text: 'Confirm',
                    role: 'confirm',
                    handler: () => sendReminder(),
                },
            ],
        })
    }

    const handleConfirm = async (data: any) => {
        if (data.length > 0) {
            console.log(`The input value is ${data}`);
            setInputValue(data);
            setShowAlert(false);

            const url = `https://wennect.info/adm/apps/events/getFamilyMembersInfo.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&MemberUserName=${data}&CommunityID=${localStorage.getItem('currentCommunityID')}`;

            try {
                const response = await fetch(url);
                const jsonData = await response.json();

                if (jsonData.constructor === Object && jsonData.family_info) {
                    // console.log('Family info present, need to do family checkin');
                    window.localStorage.setItem('family_info', JSON.stringify(jsonData.family_info));
                    setAdminCheckIn(true);
                    setIsCheckInOpen(true);
                } else if (jsonData.constructor === Object && jsonData.CODE === "NOFAMMAPINFO") {
                    const url = `https://wennect.info/adm/apps/events/doEventCheckIn.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}"}&MemberUserName=${data}`;
                    try {
                        const response = await fetch(url);
                        const jsonData = await response.json();

                        if (jsonData.constructor === Object && jsonData.CODE && jsonData.CODE === "SUCCESS") {
                            setToastMessage(jsonData.DESCRIPTION);
                            setToastType('success');
                        } else if (jsonData.constructor === Object && jsonData.DESCRIPTION) {
                            setToastMessage(jsonData.DESCRIPTION);
                            setToastType('danger');
                        } else {
                            setToastMessage('Unexpected Response: ' + jsonData);
                            setToastType('danger');
                        }
                        setShowToast(true);
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Invalid Username')
        }
    }

    async function handleDelConfirm() {
        const url = `https://wennect.info/adm/apps/events/delCommEvent.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}"}`;
        console.log(url)
        try {
            setLoading(true);
            setLoadMessage('Deleting Event...')
            const response = await fetch(url);
            const data = await response.json();
            if (data.constructor === Object && data.CODE && data.CODE === "SUCCESS") {
                if (!props.hideDetails) {
                    window.sessionStorage.clear()
                    window.localStorage.removeItem('family_info');
                    props.checkFetching();
                }
                setToastType('success')
                setToastMessage('Deleted Event')
                setShowToast(true)
                props.setRefresh(true);
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setShowToast(true);
                setToastMessage(data.DESCRIPTION);
                setToastType("danger");
            } else {
                setShowToast(true);
                setToastMessage(data);
                setToastType("danger");
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }

    async function getReport() {
        setLoading(true);
        setLoadMessage('Fetching Report...')
        const url = `https://wennect.info/apps/events/getData.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&EventID=${props.event.event_id}&commID=${localStorage.getItem('currentCommunityID')}`;
        console.log(url)
        try {
            const response = await fetch(url);
            const jdata = await response.json();
            console.log(jdata);
            if (jdata.constructor === Object && jdata.CODE && jdata.CODE === 'FAILED' && jdata.CODE !== 'NODATA') {
                setToastMessage(jdata.DESCRIPTION);
                setToastType('danger');
                setShowToast(true);
            } else {
                if (jdata.CODE === 'NODATA') {
                    setToastMessage(jdata.DESCRIPTION);
                    setToastType('success');
                    setShowToast(true);
                } else {
                    console.log(jdata);
                    setReportData(jdata);
                    sessionStorage.setItem('report', JSON.stringify(jdata));
                    console.log(jdata.event_title);
                    let attend: any = Object.entries(jdata.Attending)
                    let decline: any = Object.entries(jdata.Declined)
                    let thaal: any = Object.entries(jdata.thaal_stats)
                    setAttending(attend);
                    setDeclined(decline);
                    setThaalStats(thaal);
                    console.log('Displaying Report Modal...');
                    setShowReport(true);
                }
            }
        } catch (error: any) {
            console.log(error)
            setToastMessage(error.message);
            setToastType('danger');
            setShowToast(true);
        } setLoading(false);
    }

    return (
        <>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            <IonAlert
                isOpen={showDelAlert}
                onDidDismiss={() => setShowDelAlert(false)}
                header={'Delete Event'}
                message={'Are you sure you want to delete this event?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowDelAlert(false)
                    },
                    {
                        text: 'Delete',
                        handler: () => { handleDelConfirm() }
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Enter Username'}
                inputs={[
                    {
                        name: 'input',
                        type: 'text',
                        placeholder: 'Enter Username',
                        attributes: {
                            minlength: 3
                        }
                    }
                ]}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowAlert(false)
                    },
                    {
                        text: 'Confirm',
                        handler: (alertData) => { handleConfirm(alertData.input) }
                    }
                ]}
            />
            <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={1800} />
            <Report reportData={reportData} attending={attending} declined={declined} thaalStats={thaalStats} setShowToast={setShowToast} setToastMessage={setToastMessage} setToastType={setToastType} event={props.event.event_id} showReport={showReport} setShowReport={setShowReport} loading={loading} setLoading={setLoading} loadMessage={loadMessage} setLoadMessage={setLoadMessage} />
            {searchCheck() && <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        <IonGrid>
                            <IonRow class="ion-justify-content-end">
                                {!props.hideDetails && <IonCol size="auto">
                                    <div color="primary" className="text-box__date">
                                        <IonRow>
                                            {EventStartDate.toLocaleString('en-US', { day: '2-digit' })}
                                        </IonRow>
                                        <IonRow color="primary" className="text-box__month">
                                            {EventStartDate.toLocaleString('en-US', { month: 'short' })}
                                        </IonRow>
                                    </div>
                                </IonCol>}
                                <IonCol className="text-box__title" >
                                    <div>
                                        <IonRow class="ion-justify-content-end">
                                            <IonCol>
                                                <IonRow>
                                                    <div className='event-title'>
                                                        {props.event.event_title}
                                                    </div>
                                                </IonRow>
                                                <IonRow>
                                                    <div className='smaller-text'>
                                                        <IonIcon icon={timeOutline} />&nbsp;{formattedDate + ' ' + props.event.event_time_start.split(':')[0] + ':' + props.event.event_time_start.split(':')[1]}
                                                        <br />
                                                        {props.event.hide_end_date === 0 && <><IonIcon icon={timeOutline} />&nbsp;</>}
                                                        {props.event.hide_end_date === 0 && formattedDateEnd + ' ' + props.event.event_time_end.split(':')[0] + ':' + props.event.event_time_end.split(':')[1]}
                                                    </div>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                </IonCol>
                                <IonCol size="auto">
                                    <IonButtons>
                                        {!props.hideDetails && (window.localStorage.getItem('isCommunityAdmin') === 'true' || window.localStorage.getItem('iAmEventsAdmin')) &&
                                            <IonButton fill="clear" color="medium" onClick={() => { setOpenSelections(!openSelections) }} slot="end">
                                                {!openSelections && <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />}
                                                {openSelections && <IonIcon slot="icon-only" icon={closeOutline} />}
                                            </IonButton>
                                        }
                                    </IonButtons>
                                </IonCol>
                            </IonRow>
                            {!props.hideDetails && openSelections &&
                                <IonRow class="ion-justify-content-around">
                                    <IonButtons style={{ display: 'flex' }}>
                                        <IonButton fill="clear" size="small" className="event-more-button" onClick={() => { getReport(); }} >
                                            {/* <div className="wrap-text">Guest List</div> */}
                                            <IonIcon slot='start' size="small" icon={documentTextOutline} />Reports
                                        </IonButton>
                                        <IonButton fill="clear" size="small" className="event-more-button" onClick={() => { confirmReminder() }} >
                                            {/* <div className="wrap-text">Send Reminder</div> */}
                                            <IonIcon slot='start' size="small" icon={notificationsOutline} />Reminder
                                        </IonButton>
                                        {/* {props.event.reqChkIn === "true" && */}
                                        <IonButton fill="clear" size="small" className="event-more-button" onClick={() => { setShowAlert(true) }} >
                                            {/* <div className="wrap-text">Admin Check-In</div> */}
                                            <IonIcon slot='start' size="small" icon={locationOutline} />Check-In
                                        </IonButton>
                                        {/* } */}
                                    </IonButtons>
                                </IonRow>
                            }
                        </IonGrid>
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <div className='event-text'>
                        {props.event.event_desc}

                        {props.event.location.length > 0 && <><br /><br />
                            <IonIcon color="primary" size="small" slot="start" icon={locationOutline} />&nbsp;<IonText color="primary">&nbsp;{props.event.location}</IonText></>}

                        {props.event.chkInWarnMsg.length > 0 && <><br /><br />
                            <IonIcon color="primary" size="small" slot="start" icon={warningOutline} />&nbsp;<IonText color="primary">&nbsp;{props.event.chkInWarnMsg}</IonText></>}
                    </div>
                </IonCardContent>
                {!props.hideDetails && <IonCardSubtitle>
                    <IonGrid>
                        <IonRow class="ion-justify-content-between">
                            <IonCol size="auto">
                                <IonButtons>
                                    <div className='smaller-text'>
                                        {props.event.mypost === "true" && props.event.event_title === "eastereggbunnyishere" && localStorage.getItem('currentCommunityName') === "Alaqmar Test" && 
                                            <IonButton onClick={() => { setAddGuestsModal(true); }}>
                                                Add Guests
                                            </IonButton>
                                        }
                                        {props.event.rsvpmethod !== "None" && <IonButton id="rsvp-modal" style={{ fontsize: 8 }} onClick={() => {
                                                setIsRsvpOpen(true);
                                            }}>
                                                <IonIcon size="small" slot="start" icon={arrowUndoOutline} />
                                                {props.event.rsvp_info.rsvp_id === "NORSVP" && "RSVP"}
                                                {props.event.rsvp_info.rsvp_id !== "NORSVP" && "Edit RSVP"}
                                            </IonButton>}
                                        {props.event.reqChkIn === "true" &&
                                            <IonButton style={{ fontsize: 8 }} onClick={() => { checkFamMap(); }} >
                                                {props.event.chkInDone === false && <IonIcon size="small" slot="start" icon={locationOutline} />}
                                                {props.event.chkInDone === true && <IonIcon size="small" slot="start" icon={checkmarkDoneOutline} />}
                                                {props.event.chkInDone === false && "Check In"}
                                                {props.event.chkInDone === true && "Checked In"}
                                            </IonButton>}
                                    </div>
                                </IonButtons>
                            </IonCol>
                            {(window.localStorage.getItem('isCommunityAdmin') === 'true' || window.localStorage.getItem('iAmEventsAdmin')) &&
                                <IonCol size="auto">
                                    <IonButtons>
                                    
                                        {props.event.mypost === "true" && <IonButton onClick={() => { setShowModal(true); setEditingEvent(false); setCanSubmit(true); }}>
                                            <IonIcon size="small" slot="icon-only" icon={copyOutline} />
                                        </IonButton>}

                                        {props.event.mypost === "true" && <IonButton onClick={() => { setShowModal(true); setEditingEvent(true); setCanSubmit(true); }}>
                                            <IonIcon size="small" slot="icon-only" icon={pencilOutline} />
                                        </IonButton>}

                                        {props.event.mypost === "false" && <IonButton onClick={() => { setShowModal(true); setEditingEvent(false); setCanSubmit(false); }}>
                                            <IonIcon size="small" slot="icon-only" icon={eyeOutline} />
                                        </IonButton>}

                                        {props.event.mypost === "true" && <IonButton onClick={() => setShowDelAlert(true)}>
                                            <IonIcon size="small" slot="icon-only" icon={trashOutline} />
                                        </IonButton>}
                                    </IonButtons>
                                </IonCol>}
                        </IonRow>
                    </IonGrid>
                </IonCardSubtitle>}
                <EventAddGuestsModal setRefresh={props.setRefresh} loading={checkInLoadMessage} loadMessage={checkInLoadMessage} setLoading={setCheckInLoading} setLoadMessage={setLoadMessage} refresh={props.refresh} addGuestsModal={addGuestsModal} setAddGuestsModal={setAddGuestsModal} event={props.event} />
                <EventCheckInModal setRefresh={props.setRefresh} loading={checkInLoading} loadMessage={checkInLoadMessage} setLoading={setCheckInLoading} setLoadMessage={setCheckInLoadMessage} adminCheckIn={adminCheckIn} refresh={props.refresh} isCheckInOpen={isCheckInOpen} setIsCheckInOpen={setIsCheckInOpen} event={props.event} memberName={inputValue} />
                <EventRsvpModal setRefresh={props.setRefresh} refresh={props.refresh} checkFetching={props.checkFetching} isRsvpOpen={isRsvpOpen} setIsRsvpOpen={setIsRsvpOpen} event={props.event} />
                <CreateEvent
                    refresh={props.refresh}
                    hideDetails={props.hideDetails}
                    checkFetching={props.checkFetching}
                    setRefresh={props.setRefresh}
                    eventStartDate={props.event.event_date_start}
                    eventEndDate={props.event.event_date_end}
                    eventStartTime={props.event.event_time_start.split(':')[0] + ':' + props.event.event_time_start.split(':')[1]}
                    eventEndTime={props.event.event_time_end.split(':')[0] + ':' + props.event.event_time_end.split(':')[1]}
                    eventTitle={props.event.event_title}
                    eventDesc={props.event.event_desc}
                    externalLink={props.event.ext_link}
                    eventLocation={props.event.location}
                    rsvpType={props.event.rsvp_info.rsvp_id}
                    hideEnd={props.event.hide_end_date}
                    checkInRequired={props.event.reqChkIn}
                    checkInWarning={props.event.chkInWarnMsg}
                    prevRemoteCheckIn={props.event.prevRemoteCheckIn}
                    checkInAddress={props.event.event_loc_address}
                    showGuests={props.event.showGuestList}
                    notis={'true'}
                    canSubmit={canSubmit}
                    editingEvent={editingEvent}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    eventID={props.event.event_id}
                />
            </IonCard>}
        </>
    );
}

// checkInWarning: '',
// prevRemoteCheckIn: false,
// checkInAddress: '',
// showGuests: false,
// notis: true,
// oneClick: false,

export default Event;
export { getCurrentPosition }