import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../../components/ExploreContainer';
import './Classifieds.css';
import AppHeader from '../AppHeader';

const Classifieds: React.FC = () => {
  return (
    <IonPage>
      <AppHeader title="Classifieds" searchFor='Classifieds' />
      <IonContent fullscreen>
        <ExploreContainer name="Develpment In Progress!" />
      </IonContent>
    </IonPage>
  );
};

export default Classifieds;
