import { useState, useRef } from 'react';
import { IonLoading, IonHeader, IonToolbar, IonTitle, IonToast, IonModal, IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import NumberPicker from '../NumberPicker/NumberPicker';

function DefaultRsvpModal(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const splitWheels = ['Men', 'Women', 'Boys', 'Girls']
    const [saving, setSaving] = useState(false);
    // console.log(props.rsvpInfo)

    // async function getDefaults() {
    //     const url = `https://wennect.info/api/reqUserInfo.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}`;
    //     console.log(url);
    //     try {
    //         const response = await fetch(url);
    //         const data = await response.json();
    //         console.log(data);

    //         if (data.constructor === Object && (data.V1CLK_RSVP_MEN || data.V1CLK_RSVP_WOMEN || data.V1CLK_RSVP_BOYS || data.V1CLK_RSVP_GIRLS)) {
    //             setRsvpInfo({
    //                 'Men': data.V1CLK_RSVP_MEN === null ? 0 : data.V1CLK_RSVP_MEN,
    //                 'Women': data.V1CLK_RSVP_WOMEN === null ? 0 : data.V1CLK_RSVP_WOMEN,
    //                 'Boys': data.V1CLK_RSVP_BOYS === null ? 0 : data.V1CLK_RSVP_BOYS,
    //                 'Girls': data.V1CLK_RSVP_GIRLS === null ? 0 : data.V1CLK_RSVP_GIRLS
    //             })
    //         } else if (data.constructor === Object && data.DESCRIPTION) {
    //             console.log(data);
    //             setShowToast(true);
    //             setToastMessage("hi" + data.DESCRIPTION);
    //             setToastType("danger");
    //         } else {
    //             setShowToast(true);
    //             setToastMessage(data);
    //             setToastType("danger");
    //         }
    //     } catch (e: any) {
    //         setShowToast(true);
    //         setToastMessage(e)
    //         setToastType("danger");
    //     }
    // }

    // if (props.getDefaults) {
    //     props.setGetDefaults(false);
    //     getDefaults();
    // }

    async function submitRsvpInfo() {
        setSaving(true);
        const url = `https://wennect.info/apps/events/1ClkRSVPCntUpdate.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&EventRSVPObj={"Men":"${props.rsvpInfo['Men']}","Women":"${props.rsvpInfo['Women']}","Boys":"${props.rsvpInfo['Boys']}","Girls":"${props.rsvpInfo['Girls']}"}`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === "SUCCESS") {
                props.setShowToast(true);
                props.setToastMessage("Default RSVP Updated");
                props.setToastType("success");
            } else if (data.constructor === Object && data.DESCRIPTION) {
                props.setShowToast(true);
                props.setToastMessage(data.DESCRIPTION);
                props.setToastType("danger");
            } else {
                props.setShowToast(true);
                props.setToastMessage("hi" + data);
                props.setToastType("danger");
            }
        } catch (e: any) {
            props.setShowToast(true);
            props.setToastMessage("hi" + e.message)
            props.setToastType("danger");
        }
        setSaving(false);
        dismiss();
    }
    
    function dismiss() {
        props.setOpen(false);
        modal.current?.dismiss();
    }

    return <>
        <IonToast color={props.toastType} isOpen={props.showToast} position="middle" onDidDismiss={() => props.setShowToast(false)} message={props.toastMessage} duration={1000} />
        <IonLoading isOpen={saving} message="saving..." spinner="circles" />
        <IonModal isOpen={props.open} onDidDismiss={dismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Update Default RSVP</IonTitle>
                        <IonButton slot="end" fill="clear" onClick={() => { dismiss() }}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonGrid>
                <IonRow>    {/* Number Pickers for RSVP counts */}
                    {splitWheels.map((subject: string) => {
                        type RsvpInfoObjKey = keyof typeof props.rsvpInfo;
                        return <IonCol key={subject}><NumberPicker columnHeading={subject} value={props.rsvpInfo[subject as RsvpInfoObjKey]} rsvpInfo={props.rsvpInfo} setRsvpInfo={props.setRsvpInfo} /></IonCol>
                    })}
                </IonRow>
                <IonRow class="ion-justify-content-center">    {/* RSVP BUTTON ---------- */}
                    <IonButton fill="outline" expand="block" onClick={submitRsvpInfo}>
                        <IonIcon slot="start" icon={checkmarkOutline} />
                        Save
                    </IonButton>
                </IonRow>
            </IonGrid>
        </IonModal>
    </>;
}

export default DefaultRsvpModal;