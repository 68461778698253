import { useState, useRef } from 'react';

import { IonLoading, useIonAlert, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonCheckbox, IonTitle, IonContent, IonIcon, IonText, IonSegment, IonSegmentButton, IonLabel, IonGrid, IonCol, IonRow, IonToast, IonList, IonItem } from '@ionic/react';
import { closeOutline, locationOutline, warningOutline } from 'ionicons/icons';

import { getCurrentPosition } from './Event';

function EventCheckInModal(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage]: any = useState("");
    const [toastType, setToastType]: any = useState("");
    const [data, setData] = useState(window.localStorage.getItem('family_info'));
    // const [defaultSelectedFamilyMembers, setDefaultSelectedFamilyMembers] = useState();
    const [selectedFamilyMembers, setSelectedFamilyMembers] = useState({});
    const [presentAlert] = useIonAlert();

    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');

    // let jsonDataFam;
    // try{
    //     jsonDataFam = JSON.parse(window.localStorage.getItem('family_info') as string);
    // } catch (e) {
    //     jsonDataFam = [];
    // }

    // if (jsonDataFam === null){
    //     jsonDataFam = [];
    // }

    // jsonDataFam.map((member: any) => {
    //     return defaultSelectedFamilyMembers[member.UserID] = false;
    // })

    async function submitHandler() {
        try {
            let membersList = [];

            for (const [member, checkedIn] of Object.entries(selectedFamilyMembers)) {
                if (checkedIn === true) {
                    membersList.push(member);
                }
            }

            if (membersList.length === 0) {
                setToastMessage("Please Select At Least One Member To Check-In");
                setToastType('danger');
                setShowToast(true);
            } else {
                setLoading(true);
                setLoadMessage('Checking In...');

                let geoPos = {
                    long: '',
                    lat: '',
                };
        
                if (props.event.chkInGeoProxReq === 'true') {
                    geoPos = await getCurrentPosition();
                }

                // console.log(membersList.join(','));
                let url = `https://wennect.info/apps/events/doEventCheckIn.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}","lat":"${geoPos.lat}","long":"${geoPos.long}","location":"undefined"}&familyChkInInfo={"familyUserIDs":"${membersList.join(',')}"}&doaddresslookup`;

                if (props.adminCheckIn === true) {
                    url = `https://wennect.info/adm/apps/events/doEventCheckIn.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}"}&MemberUserName=${props.memberName}&familyChkInInfo={"familyUserIDs":"${membersList.join(',')}"}`;
                }

                const response = await fetch(url);
                const JSONdata = await response.json();
                // console.log(JSONdata);
                // if (JSONdata.CODE !== 'SUCCESS') {
                //     setToastMessage(JSONdata.DESCRIPTION);
                //     setToastType('danger');
                // } else {
                //     dismiss();
                //     setToastMessage(JSONdata.DESCRIPTION);
                //     setToastType('success');
                // }

                if (JSONdata.constructor === Object && JSONdata.DESCRIPTION) {
                    if (JSONdata.CODE && JSONdata.CODE === 'BADLOCATION') {
                        //   ToastService.showToast(response.data.DESCRIPTION);
                        //   setToastMessage('CheckIn Error', JSONdata.DESCRIPTION);
                        //   setToastType('danger');
                        presentAlert({
                            header: 'Must Check In at Location',
                            buttons: [
                                {
                                    text: 'Confirm',
                                    role: 'confirm',
                                },
                            ],
                        })
                    } else {
                        setToastMessage(JSONdata.DESCRIPTION);
                        setToastType('success')
                        props.setRefresh(true);
                    }
                } else {
                    setToastMessage("Unexpected response: " + JSONdata);
                    setToastType('danger');
                }
            }
        } catch (e: any) {
            setToastMessage(e.message);
            setToastType('danger');
        } 
        setLoading(false);

        let user = localStorage.getItem('username');
        if (user !== null && !localStorage.getItem('family_info')?.includes(user)) {
            localStorage.removeItem('family_info');
        }

        dismiss();
        setShowToast(true);
    }

    function setDefaultSelectedFamilyMembers() {
        try {
            if (data === null) {
                throw new Error('insufficient data')
            } else {
                setData(window.localStorage.getItem('family_info'))
                if (data !== "NOFAMMAPINFO") {
                    let jsonData = JSON.parse(data); // ------------ TODO: THIS IS BROKEN FIX --------------

                    jsonData.map((member: any) => {
                        return setSelectedFamilyMembers((prevSelectedFamilyMembers) => { return { ...prevSelectedFamilyMembers, [member.UserID]: false } })
                    })
                }
            }
        } catch (e: any) {
            console.log(e.message);
            props.setRefresh(true);
            return <></>
        }
    }

    function listFamily() {
        if (window.localStorage.getItem('family_info') !== '') {
            try {
                if (data === 'NOFAMMAPINFO') {
                    props.setLoading(false);
                    return <></>;
                    // console.log('Family info not present, need to do current user checkin');
                } else if (data === null || window.localStorage.getItem('family_info') === "undefined" || window.localStorage.getItem('family_info') === null) {
                    props.setLoading(false);
                    getFamilyMembersInfo();
                    throw new Error('insufficient data');
                } else {
                    props.setLoading(false);
                    let jsonData = JSON.parse(data);            // ------------ THIS IS BROKEN FIX --------------
                    return jsonData.map((member: any) => {
                        return <IonItem key={member.UserID}>
                            <IonCheckbox slot="start" onIonChange={(event) => {
                                setSelectedFamilyMembers((prevSelectedFamilyMembers) => { return { ...prevSelectedFamilyMembers, [member.UserID]: event.detail.checked } })
                            }} />
                            <IonLabel>{member.FName + " " + member.LName}</IonLabel>
                        </IonItem>
                    })
                }
            } catch (e: any) {
                props.setLoading(false);
                console.log(e.message);
                props.setRefresh(true);
                return <></>
            }
        }
    }

    const getFamilyMembersInfo = async () => {
        if (window.localStorage.getItem('family_info') !== "undefined") {
            props.setLoading(true);
            props.setLoadMessage("Getting Family Info...");
            let url = `https://wennect.info/apps/events/getFamilyMembersInfo.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&CommunityID=${window.localStorage.getItem('currentCommunityID')}`;
            // console.log(url);
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                if (jsonData.constructor === Object && jsonData.CODE && jsonData.CODE === 'NOFAMMAPINFO') { //No Family to checkIn, checkIn the logged in user
                    // Singe CheckIn
                    // console.log('Family info not present, need to do current user checkin');
                    window.localStorage.setItem('family_info', jsonData.CODE);
                    setData(jsonData.CODE);
                } else if (jsonData.constructor === Object && jsonData.CODE) {
                    setShowToast(true);
                    setToastMessage(jsonData.DESCRIPTION)
                    setToastType("danger");
                } else if (jsonData.constructor === Object && jsonData.family_info) {
                    // console.log('Family info present, need to do family checkin');
                    window.localStorage.setItem('family_info', JSON.stringify(jsonData.family_info));
                    setData(JSON.stringify(jsonData.family_info));
                } else {
                    // ToastService.showToast("Unexpected response: " + jsonData);
                    setShowToast(true);
                    setToastMessage("Unexpected response when retreiving family mapping info: " + jsonData)
                    setToastType("danger");
                }
                // console.log(response);
                // console.log(jsonData);
                // window.localStorage.setItem('family_info', JSON.stringify(jsonData.family_info));
                
                // setData(JSON.stringify(jsonData.family_info));
            } catch (e: any) {
                setShowToast(true);
                setToastMessage(e.message)
                setToastType("danger");
            }
            props.setLoading(false);
        }
        return <></>;
    };

    // function addMember(memberID: any) {
    //     if (!checkingIn.includes(memberID)) {
    //         checkingIn.push(memberID);
    //         console.log(checkingIn);
    //     } else {
    //         checkingIn.splice(checkingIn.indexOf(memberID), 1);
    //         console.log(checkingIn);
    //     }
    // }

    function dismiss() {
        props.setIsCheckInOpen(false);
        modal.current?.dismiss();
    }

    if (data === null && window.localStorage.getItem('family_info') === null) {
        window.localStorage.setItem('family_info', '');
        getFamilyMembersInfo();
    }

    return (
        <>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            <IonLoading isOpen={props.loading} message={props.loadMessage} spinner="circles" />
            <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={2000} />
            <IonModal isOpen={props.isCheckInOpen} onDidPresent={setDefaultSelectedFamilyMembers}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Check-In</IonTitle>
                        <IonButtons onClick={() => { dismiss() }} slot="end">
                            <IonButton onClick={() => { dismiss() }}>
                                <IonIcon icon={closeOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonGrid>
                        <IonRow>    {/* Buttons to choose people Checking In */}
                            <IonCol>{listFamily()}</IonCol>
                        </IonRow>
                        <IonRow class="ion-text-center">    {/* Message To Display */}
                            <IonCol><IonIcon color="primary" size="small" slot="start" icon={warningOutline} />&nbsp;
                                <IonText color="primary">{props.event.chkInWarnMsg}</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-justify-content-center">    {/* RSVP BUTTON ---------- */}
                            <IonButton type="submit" fill="outline" expand="block" onClick={() => { submitHandler() }}>
                                <IonIcon slot="start" icon={locationOutline} />
                                Check-In
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
        </>
    );
}

export default EventCheckInModal;