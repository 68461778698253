import { useState, useRef } from 'react';
import { IonChip, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonSearchbar, IonTitle, IonToolbar, IonText, IonNav, IonNote, IonList } from "@ionic/react";
import { closeCircle, closeOutline } from "ionicons/icons";
import './Events.css';

function SelectGuestsModal(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const [members, setMembers]: any = useState(null);
    const [filter, setFilter] = useState("");

    async function getMembers() {
        const url = `https://wennect.info/apps/events/getMembersList.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem("accessToken")}"}&commID=${localStorage.getItem('currentCommunityID')}`;
        console.log(url);
        if (localStorage.getItem('members') === null) {
            try {
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);

                setMembers(data);
                localStorage.setItem('members', 'yay');
                console.log(members);
            } catch (e: any) {
            }
        }

        return <>

        </>;
    }

    function showMembers() {
        try {
            console.log(members);
            let counter = 0;
            let returning = members.member_info.map((member: any) => {
                if (member.UName.toLowerCase().indexOf(filter.toLowerCase()) > -1 || member.FName.toLowerCase().indexOf(filter.toLowerCase()) > -1 || member.LName.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                    counter++;
                    return <IonItem key={member.UName}>
                        <IonLabel>
                            <IonText>
                                {member.FName} {member.LName}
                            </IonText><br />
                            <IonNote>
                                {member.UName}
                            </IonNote>
                        </IonLabel>
                    </IonItem>;
                }
            })
            if (counter === 0) {
                returning = <IonCardHeader>
                                <IonCardTitle>
                                    No Members!
                                </IonCardTitle>
                            </IonCardHeader>;
            }

            return returning;
        } catch (e) {
            return <IonCardHeader>
                <IonCardTitle>
                    No Members!
                </IonCardTitle>
            </IonCardHeader>;
        }
    }

    if (localStorage.getItem('members') === null || members === null) {
        getMembers();
    }

    function handleSearchEvent(value: any) {
        setFilter(value);
    }

    function dismiss() {
        props.setIsOpen(false);
        modal.current?.dismiss();
    }

    return <IonModal isOpen={props.isOpen}>
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    Select Geusts
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={() => dismiss()}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
                <IonChip class="no-ripple">
                    <IonLabel>Tasneem Trunkwala</IonLabel>
                    <IonIcon onClick={() => { console.log('closing chip...') }} icon={closeCircle}></IonIcon>
                </IonChip>
            </IonContent>
            <IonToolbar>
                <IonSearchbar debounce={800} onIonChange={(e: any) => { handleSearchEvent(e.detail.value.trim()) }} value={filter} showClearButton="always" placeholder={"Search Members"} />
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <br /><br /><br /><br /><br /><br />
            <IonGrid>
                <IonList>
                    {showMembers()}
                </IonList>
            </IonGrid>
        </IonContent>
    </IonModal>;
}

export default SelectGuestsModal;