import { useState, useRef } from 'react';

import { IonLoading, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonIcon, IonSegment, IonSegmentButton, IonLabel, IonGrid, IonCol, IonRow, IonToast } from '@ionic/react';
import { closeOutline, checkmarkOutline, checkmarkCircleOutline, closeCircleOutline, helpCircleOutline } from 'ionicons/icons';
import Event from './Event';

import NumberPicker from '../../components/NumberPicker/NumberPicker';

function EventRsvpModal(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage]: any = useState();
    const [toastType, setToastType]: any = useState();
    const [rsvpConfirmation, setRsvpConfirmation] = useState(props.event.rsvp_info.confirmation);
    const splitWheels = props.event.rsvpmethod.split(",");
    const [rsvpInfo, setRsvpInfo] = useState({
        'Persons': props.event.rsvp_info.Persons === "" ? 0 : props.event.rsvp_info.Persons,
        'Men': props.event.rsvp_info.Men === "" ? 0 : props.event.rsvp_info.Men,
        'Women': props.event.rsvp_info.Women === "" ? 0 : props.event.rsvp_info.Women,
        'Boys': props.event.rsvp_info.Boys === "" ? 0 : props.event.rsvp_info.Boys,
        'Girls': props.event.rsvp_info.Girls === "" ? 0 : props.event.rsvp_info.Girls,
        'Adults': props.event.rsvp_info.Adults === "" ? 0 : props.event.rsvp_info.Adults,
        'Kids': props.event.rsvp_info.Kids === "" ? 0 : props.event.rsvp_info.Kids
    });

    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');

    async function submitRsvpInfo() {
        let url = '';
        let counter = 0
        splitWheels.map((subject: any) => {
            counter += rsvpInfo[subject as keyof typeof rsvpInfo];
        })
        if (counter === 0) {
            setTimeout(() => { setRsvpConfirmation('Declined') }, 100);
        }

        if (props.event.rsvp_info.rsvp_id === "NORSVP") {
            url = `https://wennect.info/apps/events/doEventRSVPUpdate.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&EventRSVPObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}","Confirmation":"${rsvpConfirmation}","Persons":"${rsvpInfo['Persons']}","Men":"${rsvpInfo['Men']}","Women":"${rsvpInfo['Women']}","Adults":"${rsvpInfo['Adults']}","Kids":"${rsvpInfo['Kids']}","Boys":"${rsvpInfo['Boys']}","Girls":"${rsvpInfo['Girls']}"}`;
        } else {
            url = `https://wennect.info/apps/events/doEventRSVPUpdateOW.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&EventRSVPObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","EventID":"${props.event.event_id}","Confirmation":"${rsvpConfirmation}","Persons":"${rsvpInfo['Persons']}","Men":"${rsvpInfo['Men']}","Women":"${rsvpInfo['Women']}","Adults":"${rsvpInfo['Adults']}","Kids":"${rsvpInfo['Kids']}","Boys":"${rsvpInfo['Boys']}","Girls":"${rsvpInfo['Girls']}"}`;
        }
        try {
            setLoading(true);
            setLoadMessage('RSVPing...')
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);

            if (data.constructor === Object && data.CODE && data.CODE === "SUCCESS") {
                // IonicPopupService.alert('success', 'rsvp done successfully.');
                // $scope.response = response;
                // CommonRetriveDataService.refreshAllEvent();
                window.sessionStorage.removeItem('eventData');
                window.sessionStorage.removeItem('fetching');
                props.setRefresh(true);
                setShowToast(true);
                setToastMessage("RSVP Updated");
                setToastType("success");
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setShowToast(true);
                setToastMessage(data.DESCRIPTION);
                setToastType("danger");
            } else {
                setShowToast(true);
                setToastMessage(data);
                setToastType("danger");
            }
        } catch (e: any) {
            setShowToast(true);
            setToastMessage(e.message)
            setToastType("danger");
        }
        setLoading(false);
    }

    function dismiss() {
        props.setIsRsvpOpen(false);
        setRsvpInfo({ ...rsvpInfo });
        modal.current?.dismiss();
    }

    return (
        <>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={1000} />
            <IonModal isOpen={props.isRsvpOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>RSVP</IonTitle>
                        <IonButtons onClick={() => { dismiss() }} slot="end">
                            <IonButton onClick={() => { dismiss() }}>
                                <IonIcon icon={closeOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <Event event={props.event} checkFetching={props.checkFetching} hideDetails={true} />
                    <IonGrid>
                        <IonRow>    {/* Button Bar for choosing RSVP confirmation */}
                            <IonSegment value={rsvpConfirmation}>
                                <IonSegmentButton value="Attending" onClick={() => setRsvpConfirmation("Attending")}>
                                    <IonLabel>Yes</IonLabel>
                                    <IonIcon icon={checkmarkCircleOutline}></IonIcon>
                                </IonSegmentButton>
                                <IonSegmentButton value="Maybe" onClick={() => setRsvpConfirmation("Maybe")}>
                                    <IonLabel>Maybe</IonLabel>
                                    <IonIcon icon={helpCircleOutline}></IonIcon>
                                </IonSegmentButton>
                                <IonSegmentButton value="Declined" onClick={() => setRsvpConfirmation("Declined")}>
                                    <IonLabel>No</IonLabel>
                                    <IonIcon icon={closeCircleOutline}></IonIcon>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonRow>
                        <IonRow>    {/* Number Pickers for RSVP counts */}
                            {splitWheels.map((subject: string) => {
                                type RsvpInfoObjKey = keyof typeof rsvpInfo;
                                return <IonCol key={subject + props.event.event_id}><NumberPicker setRsvpConfirmation={setRsvpConfirmation} columnHeading={subject} value={rsvpInfo[subject as RsvpInfoObjKey]} rsvpInfo={rsvpInfo} setRsvpInfo={setRsvpInfo} /></IonCol>
                            })}
                        </IonRow>
                        <IonRow class="ion-justify-content-center">    {/* RSVP BUTTON ---------- */}
                            <IonButton fill="outline" expand="block" onClick={() => { submitRsvpInfo(); }}>
                                <IonIcon slot="start" icon={checkmarkOutline} />
                                RSVP
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
        </>
    );
}

export default EventRsvpModal;