import { useState, useCallback } from 'react';

function Community(props: any): any {
    const FetchCommunityDetails = useCallback(async () => {
        // console.log(communitiesCSV);
        if (window.localStorage.getItem('communityDetails') === null) {
            const data = await fetch(`https://wennect.info/api/getCommunityDetailsByIDNew.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commIDList={"commIDList":"${window.localStorage.getItem('communitiesCSV')}"}`)
            // console.log(`https://wennect.info/api/getCommunityDetailsByIDNew.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commIDList={"commIDList":"${window.localStorage.getItem('communitiesCSV')}"}`)
            try {
                if (!data.ok) {
                    throw new Error('Something went wrong!');
                }
                const JSONdata = await data.json();
                let csv: any = localStorage.getItem('communitiesCSV');
                if (csv.indexOf(',') === -1) {
                    console.log(JSONdata.communities[0].ID);
                    let iAmEventAdmin = JSONdata.communities[0].iAmEventsAdmin;
                    let iAmAdmin = JSONdata.communities[0].iAmAdmin;
                    let communityID = JSONdata.communities[0].ID;
                    let communityDetails = JSONdata.communities[0];
                    window.localStorage.setItem('isEventAdmin', iAmEventAdmin);
                    window.localStorage.setItem('isCommunityAdmin', iAmAdmin);
                    window.localStorage.setItem('currentCommunityID', JSON.stringify(communityID));
                    window.localStorage.setItem('currentCommunityDetails', JSON.stringify(communityDetails));
                    window.localStorage.setItem('currentCommunityName', JSONdata.communities[0].c_name);
                    props.checkFetching();
                }
                window.localStorage.setItem('communityDetails', JSON.stringify(JSONdata));
                props.setRefresh(true);
            } catch (e) {
                console.log(e);
            }
        }
    }, []);

    const FetchCommunityCSV = useCallback(async () => {
        if (window.localStorage.getItem('communitiesCSV') === null) {
            try {
                const data = await fetch(`https://wennect.info/api/reqsubscriptioninfo.php?CurrentLogin={%22id%22:"${window.localStorage.getItem('username')}",%22access_ticket%22:"${window.localStorage.getItem('accessToken')}"}`);
                // console.log(data);
                if (!data.ok) {
                    console.log('SOMETHING WENT WRONG')
                    throw new Error('Something went wrong!');
                }
                const JSONdata = await data.json();
                // console.log(JSONdata.SUBSCRIPTION_LIST);
                window.localStorage.setItem('communitiesCSV', JSONdata.SUBSCRIPTION_LIST);
                FetchCommunityDetails();
            } catch (e) {
                console.log('error');
            }
        }
    }, []);

    FetchCommunityCSV();
    return <></>;
}
export default Community;