import React from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonCol, IonRow, IonButton } from '@ionic/react';

const SupportPage: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Support</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <IonRow style={{ width: '100%' }}>
            <IonCol className="ion-text-center">
              <a href="mailto:support@wennect.info?subject=Support%20Request">
                <IonButton expand="full">
                  Submit
                </IonButton>
              </a>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SupportPage;