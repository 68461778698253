import { useState } from 'react';
import { IonLoading, IonHeader, IonToolbar, IonTitle, IonToast, IonModal, IonContent, IonGrid, IonRow, IonItem, IonLabel, IonInput, IonTextarea, IonButton, IonIcon } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

function SubmitFeedback(props: any) {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [loading, setLoading] = useState(false);

    const [subject, setSubject]: any = useState();
    const [body, setBody]: any = useState();
    let clicked = false;

    async function submit() {
        console.log('submitting feedback...');
        console.log(subject);
        console.log(body);
        clicked = true;
        setLoading(true);
        const url = `https://wennect.info/api/submitFeedback.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&submitFeedbackObj={"feedbackText":"${encodeURI(escape(body))}","subject":"${encodeURI(escape(subject))}"}`;
        console.log(url);
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                setToastMessage(data.DESCRIPTION);
                setToastType('success');
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setToastMessage(data.DESCRIPTION);
                setToastType('danger');
            } else {
                setToastMessage('Unexpected Response: ' + data);
                setToastType('danger');
            }
        } catch (e) {
            console.log(e);
            setToastMessage('Error! ' + e);
            setToastType('danger');
        } setShowToast(true);
        props.setShowModal(false);
        setLoading(false);
        clicked = false;
    }

    return (<>
        <IonLoading isOpen={loading} message={'Submitting...'} />
        <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={2000} />
        <IonModal isOpen={props.showModal} onDidDismiss={() => props.setShowModal(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Submit Feedback</IonTitle>
                    <IonButton slot="end" fill="clear" onClick={() => { props.setShowModal(false); }}>
                        <IonIcon slot="icon-only" icon={closeOutline} />
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="stacked">Subject</IonLabel>
                    <IonInput value={subject} onIonChange={(e: any) => { setSubject(e.detail.value) }} placeholder="Subject" />
                </IonItem>
                <IonItem>
                    <IonTextarea placeholder="Enter your feedback here (Max 1000 chars)" onIonChange={(e: any) => { setBody(e.detail.value) }} value={body} counter={true} maxlength={1000} />
                </IonItem>
                <IonGrid>
                    <IonRow class='ion-justify-content-center'>
                        <IonButton type="submit" expand="block" fill="outline" onClick={() => {
                            if (!clicked && subject && body && subject.length > 0 && body.length > 0) {
                                submit();
                            }
                        }} >
                            <IonIcon slot="start" icon={checkmarkOutline} />
                            &nbsp;Submit
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    </>);
}

export default SubmitFeedback;