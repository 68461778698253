import { useState } from 'react';
import { IonLoading, IonToast, IonModal, IonHeader, IonToolbar, IonTitle, IonCard, IonCardContent, IonButtons, IonButton, IonIcon, IonContent, IonGrid, IonRow, IonCol, IonList, IonItem, IonSelect, IonSelectOption, IonLabel, IonInput, IonTextarea, IonPopover, IonDatetime, IonFab, IonFabButton, IonToggle, IonAlert } from '@ionic/react';
import { closeOutline, addOutline } from 'ionicons/icons';
import { default as dayjs } from 'dayjs';
function CreateEvent(props: any) {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage]: any = useState();
    const [toastType, setToastType]: any = useState();
    const [eventStartDate, setEventStartDate] = useState(props.eventStartDate);
    const [eventStartTime, setEventStartTime] = useState(props.eventStartTime);
    const [eventEndDate, setEventEndDate] = useState(props.eventEndDate);
    const [eventEndTime, setEventEndTime] = useState(props.eventEndTime);
    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showStartTimePicker, setShowStartTimePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const [showEndTimePicker, setShowEndTimePicker] = useState(false);
    const [eventTitle, setEventTitle] = useState(props.eventTitle);
    const [eventDesc, setEventDesc] = useState(props.eventDesc);
    const [externalLink, setExternalLink] = useState(props.externalLink);
    const [eventLocation, setEventLocation] = useState(props.eventLocation);
    const [rsvpType, setRsvpType] = useState(props.rsvpType);
    const [hideEnd, setHideEnd] = useState(props.hideEnd)
    const [checkInRequired, setCheckInRequired] = useState(props.checkInRequired);
    const [checkInWarning, setCheckInWarning] = useState(props.checkInWarning);
    const [prevRemoteCheckIn, setPrevRemoteCheckIn] = useState(props.prevRemoteCheckIn);
    const [checkInAddress, setCheckInAddress] = useState(props.checkInAddress);
    const [showGuests, setShowGuests] = useState(props.showGuests);
    const [notis, setNotis] = useState(props.notis);
    const [oneClick, setOneClick] = useState(props.oneClick);
    let clicked = false;

    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [isPrivateEvent, setIsPrivateEvent] = useState(false);

    function dismiss() {
        setShowGuests(props.showGuests);
        setNotis(props.notis);
        setOneClick(props.oneClick);
        setCheckInWarning(props.checkInWarning);
        setCheckInAddress(props.checkInAddress);
        setHideEnd(props.hideEnd);
        setEventDesc(props.eventDesc);
        setEventTitle(props.eventTitle);
        setExternalLink(props.externalLink);
        setEventLocation(props.eventLocation);
        setPrevRemoteCheckIn(props.prevRemoteCheckIn);
        setCheckInRequired(props.checkInRequired);
        setEventStartDate(props.eventStartDate);
        setEventStartTime(props.eventStartTime);
        setEventEndDate(props.eventEndDate);
        setEventEndTime(props.eventEndTime);
        setRsvpType(props.rsvpType);
        props.setShowModal(false); // Close the modal
    }

    function translateBoolToString(value: boolean) {
        if (value === true) {
            return 'on';
        } return 'off';
    }
    function translateBoolToInt(value: boolean) {
        if (value === true) {
            return 1;
        } return 0;
    }

    async function saveEvent() {
        clicked = true;
        // Split the eventDate string into separate variables for month, day, and year

        let API_STR = 'createCommEvent.php?';
        if (isPrivateEvent) {
            console.log('private event creation going on right now...')
            API_STR = 'createCommPrivateEvent.php?'
        } else {
            console.log('BRUHHHHHHHHHHH');
        }
        if (props.editingEvent) {
            API_STR = 'updateCommEvent.php?EventID=' + props.eventID + '&';
        }

        // Send All Props To Server Thingy
        const url = `https://wennect.info/adm/apps/events/${API_STR}CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}","title":"${encodeURI(escape(eventTitle))}","description":"${encodeURI(escape(eventDesc))}","extlink":"${encodeURI(escape(externalLink))}","StartDate":"${eventStartDate}","StartTime":"${eventStartTime}:00","endDate":"${eventEndDate}","endTime":"${eventEndTime}:00","location":"${encodeURI(escape(eventLocation))}","locaddress":"${encodeURI(escape(checkInAddress))}","rsvpmethod":"${rsvpType}","notify":"${translateBoolToString(notis)}","OneClkRsvp":"${translateBoolToString(oneClick)}","hideEndTime":"${translateBoolToString(hideEnd)}","isChkInReq":"${translateBoolToString(checkInRequired)}","chkInWarnMsg":"${encodeURI(escape(checkInWarning))}","chkInGeoProxReq":"${translateBoolToString(prevRemoteCheckIn)}","chkShowGuestList":"${translateBoolToString(showGuests)}"}`;
        console.log(url);
        if (eventTitle !== '' && eventDesc !== '' && rsvpType !== '') {
            if (checkInRequired && prevRemoteCheckIn && !checkInAddress) {
                setShowToast(true);
                setToastMessage('MISSING REQUIRED INFORMATION for Event!')
                setToastType("danger");
            } else if (dayjs().isAfter(dayjs(`${eventStartDate}T${eventStartTime}:00`)) || dayjs(`${eventEndDate}T${eventEndTime}:00`).isBefore(dayjs(`${eventStartDate}T${eventStartTime}:00`))) {
                if (dayjs().isAfter(dayjs(`${eventStartDate}T${eventStartTime}:00`))) {
                    setToastMessage('Event START DATE-TIME Cannot be in the PAST!');
                }
                else if (dayjs(`${eventEndDate}T${eventEndTime}:00`).isBefore(dayjs(`${eventStartDate}T${eventStartTime}:00`))) {
                    setToastMessage('Event END DATE-TIME Cannot be BEFORE the START!');
                }
                setShowToast(true);
                setToastType("danger");
            } else {
                try {
                    setLoading(true);
                    setLoadMessage('Creating Event...');
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.constructor === Object && data.CODE && data.CODE === "SUCCESS") {
                        props.setRefresh(true);
                        setShowToast(true);
                        setToastMessage("Event Created");
                        setToastType("success");

                        if (!props.hideDetails) {
                            window.sessionStorage.clear()
                            window.localStorage.removeItem('family_info');
                            props.checkFetching();
                        }

                        setShowGuests(false);
                        setNotis(true);
                        setOneClick(false);
                        setCheckInWarning('');
                        setCheckInAddress('');
                        setHideEnd(false);
                        setEventDesc('');
                        setEventTitle('');
                        setExternalLink('');
                        setEventLocation('');
                        setPrevRemoteCheckIn(false);
                        setCheckInRequired(false);
                        setEventStartDate('2023-01-04');
                        setEventStartTime('00:00');
                        setEventEndDate('2023-01-04');
                        setEventEndTime('00:00');
                        setRsvpType('');
                        props.setShowModal(false); // Close the modal
                    } else if (data.constructor === Object && data.DESCRIPTION) {
                        setShowToast(true);
                        setToastMessage(data.DESCRIPTION);
                        setToastType("danger");
                    } else {
                        setShowToast(true);
                        setToastMessage(data);
                        setToastType("danger");
                    }
                } catch (e: any) {
                    setShowToast(true);
                    setToastMessage(e.message)
                    setToastType("danger");
                }
                setLoading(false);
            }
        } else {
            setShowToast(true);
            setToastMessage('You are missing required information!')
            setToastType("danger");
        }
        clicked = false;
    };

    function openCreateEventModal() {
        props.setShowModal(true);
    }

    return (
        <>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={2000} />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Create Private or Public Event?'}
                buttons={[
                    {
                        text: 'Private',
                        handler: () => {setShowAlert(false); setIsPrivateEvent(true); openCreateEventModal();}
                    },
                    {
                        text: 'Public',
                        handler:() => {setShowAlert(false); setIsPrivateEvent(false); openCreateEventModal();}
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlert2}
                onDidDismiss={() => setShowAlert2(false)}
                header={'Create Private Event?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowAlert2(false),
                    },
                    {
                        text: 'Create',
                        handler:() => {setShowAlert2(false); openCreateEventModal();}
                    }
                ]}
            />
            <IonModal isOpen={props.showModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Create Event</IonTitle>
                        <IonButtons onClick={dismiss} slot="end">
                            <IonButton onClick={dismiss}>
                                <IonIcon icon={closeOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem counter={true}>
                        <IonLabel position="stacked">Event Title</IonLabel>
                        <IonInput clearInput={true} value={eventTitle} onIonChange={(e) => { setEventTitle(e.detail.value) }} placeholder="Event Title" />
                    </IonItem>
                    <IonItem counter={true}>
                        <IonLabel position="stacked">Event Description</IonLabel>
                        <IonTextarea autoGrow={true} value={eventDesc} onIonChange={(e) => { setEventDesc(e.detail.value) }} placeholder="Event Description" />
                    </IonItem>
                    <IonPopover isOpen={showStartDatePicker} onDidDismiss={() => setShowStartDatePicker(false)}>
                        <IonDatetime
                            max="2050-12-31"
                            presentation="date"
                            value={eventStartDate}
                            onIonChange={(e: any) => { setEventStartDate(e.detail.value.split('T')[0]); setShowStartDatePicker(false); }}
                        />
                    </IonPopover>
                    <IonPopover isOpen={showStartTimePicker} onDidDismiss={() => setShowStartTimePicker(false)}>
                        <IonDatetime
                            max="2050-12-31"
                            presentation="time"
                            value={eventStartTime}
                            onIonChange={(e: any) => setEventStartTime(e.detail.value)}
                        />
                    </IonPopover>
                    <IonPopover isOpen={showEndDatePicker} onDidDismiss={() => setShowEndDatePicker(false)}>
                        <IonDatetime
                            max="2050-12-31"
                            presentation="date"
                            value={eventEndDate}
                            onIonChange={(e: any) => { setEventEndDate(e.detail.value.split('T')[0]); setShowEndDatePicker(false); }}
                        />
                    </IonPopover>
                    <IonPopover isOpen={showEndTimePicker} onDidDismiss={() => setShowEndTimePicker(false)}>
                        <IonDatetime
                            max="2050-12-31"
                            presentation="time"
                            value={eventEndTime}
                            onIonChange={(e: any) => setEventEndTime(e.detail.value)}
                        />
                    </IonPopover>
                    <IonItem>
                        <IonGrid>
                            <IonRow class="ion-justify-content-around">
                                <IonButton expand="block" mode="ios" shape="round" fill="outline" onClick={() => setShowStartDatePicker(true)}>
                                    Start Date | {eventStartDate.split('T')[0]}
                                </IonButton>
                                <IonButton expand="block" mode="ios" shape="round" fill="outline" onClick={() => setShowStartTimePicker(true)}>
                                    Start Time | {eventStartTime}
                                </IonButton>
                            </IonRow>
                            <IonRow class="ion-justify-content-around">
                                <IonButton expand="block" mode="ios" shape="round" fill="outline" onClick={() => setShowEndDatePicker(true)}>
                                    End Date | {eventEndDate.split('T')[0]}
                                </IonButton>
                                <IonButton expand="block" mode="ios" shape="round" fill="outline" onClick={() => setShowEndTimePicker(true)}>
                                    End Time | {eventEndTime}
                                </IonButton>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                    <IonItem counter={true}>
                        <IonLabel position="stacked">External Link</IonLabel>
                        <IonInput clearInput={true} value={externalLink} onIonChange={(e) => setExternalLink(e.detail.value)} placeholder="Optional Event Info URL" />
                    </IonItem>
                    <IonItem counter={true}>
                        <IonLabel position="stacked">Event Location</IonLabel>
                        <IonInput clearInput={true} value={eventLocation} onIonChange={(e) => setEventLocation(e.detail.value)} placeholder="Event Location Description" />
                    </IonItem>
                    <IonList>
                        <IonItem>
                            <IonLabel position="fixed">RSVP Type</IonLabel>
                            <IonSelect interface="action-sheet" placeholder='RSVP Type' class="select-full-width" value={rsvpType} onIonChange={(e) => { setRsvpType(e.detail.value) }}>
                                <IonSelectOption key="Adults,Kids" value="Adults,Kids">Adults, Kids</IonSelectOption>
                                <IonSelectOption key="Men,Women,Boys,Girls" value="Men,Women,Boys,Girls">Men, Women, Boys, Girls</IonSelectOption>
                                <IonSelectOption key="Men,Women" value="Men,Women">Men, Women</IonSelectOption>
                                <IonSelectOption key="Boys,Girls" value="Boys,Girls">Boys, Girls</IonSelectOption>
                                <IonSelectOption key="Persons" value="Persons">Persons</IonSelectOption>
                                <IonSelectOption key="Women" value="Women">Women</IonSelectOption>
                                <IonSelectOption key="Men" value="Men">Men</IonSelectOption>
                                <IonSelectOption key="Adults,Boys,Girls" value="Adults,Boys,Girls">Adults, Boys, Girls</IonSelectOption>
                                <IonSelectOption key="Men,Boys" value="Men,Boys">Men, Boys</IonSelectOption>
                                <IonSelectOption key="Women,Girls" value="Women,Girls">Women, Girls</IonSelectOption>
                                <IonSelectOption key="None" value="None">RSVP Not Needed</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonItem>
                        <IonLabel>Hide End Time?</IonLabel>
                        <IonToggle labelPlacement="end" onIonChange={() => { setHideEnd(!hideEnd) }} checked={hideEnd} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Check In Required?</IonLabel>
                        <IonToggle labelPlacement="end" onIonChange={() => { setCheckInRequired(!checkInRequired) }} checked={checkInRequired} />
                    </IonItem>
                    {checkInRequired && <>
                        <IonItem>
                            <IonLabel>Prevent Remote Check-Ins:</IonLabel>
                            <IonToggle labelPlacement="end" onIonChange={() => { setPrevRemoteCheckIn(!prevRemoteCheckIn) }} checked={prevRemoteCheckIn} />
                        </IonItem>
                        <IonItem counter={true}>
                            <IonLabel position="stacked">CheckIn Warning Message (Optional)</IonLabel>
                            <IonTextarea autoGrow={true} value={checkInWarning} onIonChange={(e) => setCheckInWarning(e.detail.value)} placeholder="e.g. You must CheckIn as soon as you arrive to the event location" />
                        </IonItem>
                    </>}
                    {checkInRequired && prevRemoteCheckIn &&
                        <IonItem counter={true}>
                            <IonLabel position="stacked">CheckIn Address:</IonLabel>
                            <IonInput clearInput={true} value={checkInAddress} onIonChange={(e) => setCheckInAddress(e.detail.value)} placeholder="Address where check-in is allowed" slot="end" />
                        </IonItem>
                    }
                    <IonItem>
                        <IonLabel>Show Guest List?</IonLabel>
                        <IonToggle labelPlacement="end" onIonChange={() => { setShowGuests(!showGuests) }} checked={showGuests} slot="end" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Send Notification?</IonLabel>
                        <IonToggle labelPlacement="end" onIonChange={() => { setNotis(!notis) }} checked={notis} slot="end" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Enable 1-Click RSVP?</IonLabel>
                        <IonToggle labelPlacement="end" onIonChange={() => { setOneClick(!oneClick) }} checked={oneClick}/>
                    </IonItem>
                    {props.canSubmit && <IonGrid>
                        <IonRow class='ion-justify-content-center'>
                            <IonButton fill="outline" onClick={() => {
                                if (!clicked) {
                                    saveEvent();
                                }
                            }}>Save</IonButton>
                        </IonRow>
                    </IonGrid>}
                </IonContent>
            </IonModal>

            {(window.localStorage.getItem('isCommunityAdmin') === 'true' || window.localStorage.getItem('isEventAdmin') === 'true') && <IonFab slot="fixed" vertical="bottom" horizontal="end" onClick={() => {if (localStorage.getItem('isCommunityAdmin') === 'true' || localStorage.getItem('isEventAdmin') === 'true') setShowAlert(true); else {setIsPrivateEvent(true); setShowAlert2(true);}}}>
                <IonFabButton>
                    <IonIcon icon={addOutline} />
                </IonFabButton>
            </IonFab>}
        </>
    );
}

CreateEvent.defaultProps = {
    hideDetails: false,
    eventStartDate: new Date().toISOString().split('T')[0],
    eventStartTime: '00:00',
    eventEndDate: new Date().toISOString().split('T')[0],
    eventEndTime: '00:00',
    eventTitle: '',
    eventDesc: '',
    externalLink: '',
    eventLocation: '',
    rsvpType: '',
    hideEnd: false,
    checkInRequired: false,
    checkInWarning: '',
    prevRemoteCheckIn: false,
    checkInAddress: '',
    showGuests: false,
    notis: true,
    oneClick: false,
    canSubmit: true,
    editingEvent: false,
}

export default CreateEvent;