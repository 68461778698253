import { useCallback, useEffect, useState } from 'react';
import { IonContent, IonPage, IonLoading, IonRefresher, IonRefresherContent, RefresherEventDetail, IonHeader, IonToolbar, IonButtons, IonTextarea, IonItem, IonLabel, IonInput, IonTitle, IonPopover, IonModal, IonDatetime, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, IonFab, IonFabButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { people, addOutline, closeOutline } from 'ionicons/icons';

import './Events.css';

import Event from './Event';
import AppHeader from '../AppHeader';
import Community from '../../services/Community';
import CreateEvent from './CreateEvent';

const Events = (props: any) => {
  const [eventData, setEventData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMessage, setLoadMessage] = useState('');
  // const [eventDate, setEventDate] = useState('');
  // const [eventTime, setEventTime] = useState('00:00');
  // const [showDatePicker, setShowDatePicker] = useState(false);
  // const [showTimePicker, setShowTimePicker] = useState(false);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      FetchEvents('');
      event.detail.complete();
    }, 1000);
  }

  const FetchEvents = useCallback(async (param: string) => {
    // console.log(param);
    try {
      let JSONdata: any = '';
      setLoading(true);
      setLoadMessage('Fetching Events...')
      window.sessionStorage.setItem('fetching', 'true');
      const data = await fetch(`https://wennect.info/apps/events/getCommEvents.php?CurrentLogin={"id":"${window.localStorage.getItem('username')}","access_ticket":"${window.localStorage.getItem('accessToken')}"}&commEventsReqObj={"CommunityID":"${window.localStorage.getItem('currentCommunityID')}"}${param}`);
      if (!data.ok) {
        throw new Error('Something went wrong!');
      }
      JSONdata = await data.json();
      setEventData(JSONdata.events);
      window.sessionStorage.setItem('eventData', JSON.stringify(JSONdata.events));
      window.localStorage.removeItem('refresh');
      props.setRefresh(true);
    } catch (e) {
      console.log('error');
      localStorage.removeItem('currentCommunityDetails')
    }
    setLoading(false);
  }, []);

  // console.log('1st check: ' + String(window.sessionStorage.getItem('fetching') === null));
  // console.log('3rd check: ' + String(window.sessionStorage.getItem('eventData') === null));
  // console.log('2nd check: ' + String(window.localStorage.getItem('currentCommunityID') === 'undefined' || window.localStorage.getItem('currentCommunityID') === null));

  // if (window.sessionStorage.getItem('fetching') === null && window.localStorage.getItem('currentCommunityID') !== null && window.sessionStorage.getItem('eventData') === null) {
  //   console.log('hola');
  //   props.setRefresh(true);
  //   FetchEvents(); // -------------------TODO FIX DOUBLE RUN FOR FETCHEVENTS-------------------------------------------
  // }

  useEffect(() => {
    if (window.localStorage.getItem('currentCommunityID') !== null)
      FetchEvents('');
  }, []);

  function checkFetching(param = '') {
    // console.log('1st check: ' + String(window.sessionStorage.getItem('fetching') === null));
    // console.log('3rd check: ' + String(window.sessionStorage.getItem('eventData') === null));
    // console.log('2nd check: ' + String(window.localStorage.getItem('currentCommunityID') === 'undefined' || window.localStorage.getItem('currentCommunityID') === null));

    // console.log("checkFetching function called inside Events page");

    if (window.sessionStorage.getItem('fetching') === null && window.localStorage.getItem('currentCommunityID') !== null && window.sessionStorage.getItem('eventData') === null) {
      props.setRefresh(true);
      FetchEvents(param); // -------------------TODO FIX DOUBLE RUN FOR FETCHEVENTS-------------------------------------------
    }
  }

  // const saveEvent = () => {
  //   // Split the eventDate string into separate variables for month, day, and year
  //   const [date, _] = eventDate.split('T');
  //   const fullDate = date + 'T' + eventTime

  //   setEventDate('YYYY-MM-DD');
  //   setEventTime('00:00');
  //   setShowModal(false); // Close the modal
  // };

  function LoadEvents() {
    if (localStorage.getItem('eventData') === null) {
      checkFetching();
    }

    try {
      return (<>{eventData.map((event: any) => {
        return <Event key={event.event_id} refresh={props.refresh} checkFetching={checkFetching} setRefresh={props.setRefresh} event={event} hideDetails={false} />
      })}<br /><br /><br /><br /></>);
    } catch (e) {
      return <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            No Events Found!
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          There are no events at this time.
        </IonCardContent>
      </IonCard>
    }
  }

  try {
    props.setRefresh(false);
  } catch (e) {
    console.log(e);
  }

  return (
    <IonPage>
      <AppHeader showMenuButton={true} showMenu={true} setRefresh={props.setRefresh} checkFetching={checkFetching} setIsLoggedIn={props.setIsLoggedIn} title={window.localStorage.getItem('currentCommunityName')} searchFor='Events' />
      <IonContent fullscreen>
        <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
        {window.localStorage.getItem('communityDetails') === null && <Community checkFetching={checkFetching} setRefresh={props.setRefresh} />}
        {window.localStorage.getItem('currentCommunityID') !== null &&
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>}
        {window.localStorage.getItem('currentCommunityID') !== null && LoadEvents()}
        {window.localStorage.getItem('currentCommunityID') === null && <>
          {
            window.localStorage.setItem('currentCommunityName', "Events")
            // <GetCommunity setRefresh={props.setRefresh} />
          }
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                No Community Selected!
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              Please select a community by clicking on the <IonIcon size="small" color="primary" icon={people} /> icon on the top-right.
            </IonCardContent>
          </IonCard>
        </>}
        <CreateEvent showModal={showModal} setShowModal={setShowModal} setRefresh={props.setRefresh} checkFetching={checkFetching} />
      </IonContent>
    </IonPage>
  );
};

export default Events;