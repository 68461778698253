import { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { globeOutline, newspaperOutline, calendarOutline } from 'ionicons/icons';
import Events from './pages/events/Events';
import Hub from './pages/hub/Hub';
import Classifieds from './pages/classifieds/Classifieds';
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import SupportPage from './pages/support/SupportPage'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/wennect.css';

import globalconsts from './constants';
// console.log(globalconsts);

setupIonicReact();

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(window.localStorage.getItem('isLoggedIn'));
  const [refresh, setRefresh] = useState(true);
  const [devToast, setDevToast] = useState(false);

  const logDeviceInfo = async () => {
    const info = await Device.getInfo();
    console.log('--DEVICE INFO--')
    console.log(info);
  }

  useEffect(() => {
    getAPNSToken();
  })

  async function getAPNSToken() {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      // get the token
      console.log('Inside if Statement Native Platform')
      console.log('Registering')
      PushNotifications.register();
      PushNotifications.addListener('registration', token => {
          // save the token
          console.log('APNS TOKEN -----');
          console.info(token.value);
          window.localStorage.setItem('apnsToken', token.value);
      });

      PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      logDeviceInfo();
      
      try {
        const url = `https://wennect.info/api/setGCMDevRegID.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&gcmObj={"dev_name":"","reg_id":"${localStorage.getItem('apnsToken')}"}`;
        console.log(url);
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        if(data.CODE === 'INVALIDACCESSTICKET'){
          localStorage.clear();
          sessionStorage.clear();
        } else if(data.CODE === 'FAILED') {
          console.log('Server Error: ' + data);
        } else if(data.CODE === 'SUCCESS') {
          console.log('RegID Successfully updated');
        } else {
          console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  if (!localStorage.getItem('apnsToken')) {
    getAPNSToken();
  }

  return (<IonApp>
    <IonToast isOpen={devToast} position="middle" onDidDismiss={() => setDevToast(false)} message={"In Development"} duration={700} />
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path={"/" + globalconsts.base_app_URI + "events"}> {/* TODO: WHEN REMAKING THIS FOR PHONES, GET RID OF THE "/" BEFORE THE TAG THINGIES */}
            {isLoggedIn === '1' && <Events refresh={refresh} setRefresh={setRefresh} setIsLoggedIn={setIsLoggedIn} />}
            {isLoggedIn !== '1' && <Redirect to={"/" + globalconsts.base_app_URI + "log-in"} />}
          </Route>
          <Route exact path={"/" + globalconsts.base_app_URI + "hub"}>
            {isLoggedIn === '1' && <Hub />}
            {isLoggedIn !== '1' && <Redirect to={"/" + globalconsts.base_app_URI + "log-in"} />}
          </Route>
          <Route path={"/" + globalconsts.base_app_URI + "classifieds"}>
            {isLoggedIn === '1' && <Classifieds />}
            {isLoggedIn !== '1' && <Redirect to={"/" + globalconsts.base_app_URI + "log-in"} />}
          </Route>
          <Route path={"/" + globalconsts.base_app_URI + "log-in"}>
            {isLoggedIn === '1' && <Redirect to={"/" + globalconsts.base_app_URI + "events"} />}
            {isLoggedIn !== '1' && <Login setIsLoggedIn={setIsLoggedIn} setRefresh={setRefresh} />}
          </Route>
          <Route path={"/" + globalconsts.base_app_URI + "sign-up"}>
            {isLoggedIn === '1' && <Redirect to={"/" + globalconsts.base_app_URI + "events"} />}
            {isLoggedIn !== '1' && <Signup setRefresh={setRefresh} />}
          </Route>
          <Route exact path={"/" + globalconsts.base_app_URI}>
            {isLoggedIn === '1' && <Redirect to={"/" + globalconsts.base_app_URI + "events"} />}
            {isLoggedIn !== '1' && <Redirect to={"/" + globalconsts.base_app_URI + "log-in"} />}
          </Route>
          <Route exact path={"/corp/support/"}>
            <SupportPage />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="events" href={"/" + globalconsts.base_app_URI + "events"}>
            <IonIcon icon={calendarOutline} />
            <IonLabel>Events</IonLabel>
          </IonTabButton>
          <IonTabButton tab="hub" href={"/" + globalconsts.base_app_URI + "hub"} onClick={() => setDevToast(true)}>
            <IonIcon icon={globeOutline} />
            <IonLabel>Hub</IonLabel>
          </IonTabButton>
          <IonTabButton tab="classifieds" href={"/" + globalconsts.base_app_URI + "classifieds"} onClick={() => setDevToast(true)}>
            <IonIcon icon={newspaperOutline} />
            <IonLabel>Classifieds</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>)
}

export default App;
