import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../../components/ExploreContainer';
import './Hub.css';
import AppHeader from '../AppHeader';

const Hub: React.FC = () => {
  return (
    <IonPage>
      <AppHeader title="Hub" searchFor='Hub' />
      <IonContent fullscreen>
        <ExploreContainer name="Develpment In Progress!" />
      </IonContent>
    </IonPage>
  );
};

export default Hub;
