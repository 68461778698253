import { useState, useRef } from 'react';
import { IonLoading, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonGrid, IonRow, IonTextarea } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import SelectGuestsModal from './SelectGuestsModal';

import './Events.css';

function EventAddGuestsModal(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const [getCsv, setGetCsv] = useState(false);
    const [selectGuests, setSelectGuests] = useState(false);
    function dismiss() {
        props.setAddGuestsModal(false);
        setGetCsv(false);
        modal.current?.dismiss();
    }

    return <>
        <SelectGuestsModal isOpen={selectGuests} setIsOpen={setSelectGuests} />
        <IonModal isOpen={props.addGuestsModal} onDidDismiss={() => {
            dismiss();
        }}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Add Guests
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => {dismiss()}}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow class="ion-align-items-center ion-justify-content-center button-block">
                        <IonButton expand="block" onClick={() => {setSelectGuests(true);}}>
                            Search For Guests
                        </IonButton>
                    </IonRow>
                    <IonRow class="ion-align-items-center ion-justify-content-center button-block">
                        <IonButton expand="block" onClick={() => {setGetCsv(!getCsv);}}>
                            Enter List
                        </IonButton>
                    </IonRow>
                    {getCsv && 
                    <>
                        <IonRow class="ion-justify-content-center">
                            <IonTextarea
                                label="Guest List"
                                labelPlacement="floating"
                                fill="outline"
                                placeholder="Enter List"
                            ></IonTextarea>
                        </IonRow>
                        <IonRow class="ion-justify-content-center">
                            <IonButton onClick={() => {setGetCsv(!getCsv);}}>
                                Submit List
                            </IonButton>
                        </IonRow>
                    </>
                    }
                </IonGrid>
            </IonContent>
        </IonModal>
    </>;
}

export default EventAddGuestsModal;