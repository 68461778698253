import { useState } from 'react';
import { IonLoading, IonHeader, IonToolbar, IonTitle, IonToast, IonModal, IonContent, IonGrid, IonRow, IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

function EditProfileModal(props: any) {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [loading, setLoading] = useState(false);
    let clicked = false;

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);


    async function getCountriesStates(countryid: any = props.countryid) {
        let url = 'https://wennect.info/api/getCountryList.php';

        try {
            const response = await fetch(url);
            const data = await response.json();

            setCountries(data);
        } catch (error) {
            console.log(error);
            setToastMessage('Error getting countries');
            setToastType('danger');
            setShowToast(true);
        }

        url = `https://wennect.info/api/getStateList.php?countryid=${countryid}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            setStates(data);
        } catch (error) {
            console.log(error);
            setToastMessage('Error getting states');
            setToastType('danger');
            setShowToast(true);
        }
    }

    function validate() {
        try {
            if (props.email2 !== null && props.email2.length > 0 && !props.email2.includes('@')) {
                setToastMessage("Please Include an '@' in the Alternate Email");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else if (props.fname === null || props.fname.length < 3 || props.fname.length > 90) {
                if (props.fname.length === 0)
                    setToastMessage("Please Enter a First Name");
                else
                    setToastMessage("First Name must be between 3 and 90 characters");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else if (props.address1 === null || props.address1.length < 3 || props.address1.length > 240) {
                if (props.address1.length === 0)
                    setToastMessage("Please Enter an Address");
                else
                    setToastMessage("Address must be between 3 and 240 characters");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else if (props.city === null || props.city.length < 2 || props.city.length > 90) {
                if (props.city.length === 0)
                    setToastMessage("Please Enter a City");
                else
                    setToastMessage("City must be between 2 and 240 characters");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else if (props.countryid === null) {
                setToastMessage("You must select a valid country");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else if (props.stateid === null) {
                setToastMessage("You must select a valid state");
                setToastType('danger');
                setShowToast(true);
                return false;
            } else {
                return true;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async function updateProfileInfo() {
        clicked = true;
        setLoading(true);
        const url = `https://wennect.info/api/updateUserInfo.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&updateObj={"f_name":"${encodeURI(escape(props.fname))}","l_name":"${encodeURI(escape(props.lname))}","ad1":"${encodeURI(escape(props.address1))}","ad2":"${encodeURI(escape(props.address2))}","city":"${encodeURI(escape(props.city))}","stateid":"${props.stateid}","zp":"${encodeURI(escape(props.zip))}","country":"${props.countryid}","cphone":"${encodeURI(escape(props.phone))}","wphone":"${encodeURI(escape(props.wphone))}","e_mail":"${encodeURI(escape(props.email))}","e_mail2":"${encodeURI(escape(props.email2))}","prefcom":""}`;
        console.log(url);
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                setToastMessage(data.DESCRIPTION);
                setToastType('success');
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setToastMessage(data.DESCRIPTION);
                setToastType('danger');
            } else {
                setToastMessage('Unexpected Response: ' + data);
                setToastType('danger');
            }
        } catch (e) {
            console.log(e);
            setToastMessage('Error! ' + e);
            setToastType('danger');
        } setShowToast(true);
        props.setShowModal(false);
        setLoading(false);
        clicked = false;
    }

    if (countries.length === 0) {
        getCountriesStates();
    }

    return (<>
        <IonLoading isOpen={loading} message={'Updating...'} />
        <IonToast color={toastType} isOpen={showToast} position="middle" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={1000} />
        <IonModal isOpen={props.showModal} onDidDismiss={() => props.setShowModal(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Edit Profile</IonTitle>
                    <IonButton slot="end" fill="clear" onClick={() => { props.setShowModal(false); }}>
                        <IonIcon slot="icon-only" icon={closeOutline} />
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="stacked">User Name</IonLabel>
                    <IonInput disabled={true} value={localStorage.getItem('username')} placeholder="User Name" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput disabled={true} value={props.email} placeholder="Email" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Alternate Email</IonLabel>
                    <IonInput clearInput={true} type="email" value={props.email2} onIonChange={(e) => props.setEmail2(e.detail.value)} placeholder="Alternate Email" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">First Name</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.fname} onIonChange={(e) => props.setFname(e.detail.value)} placeholder="First Name" minlength={3} maxlength={90} required={true} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Last Name</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.lname} onIonChange={(e) => props.setLname(e.detail.value)} placeholder="Last Name" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Address 1</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.address1} onIonChange={(e) => props.setAddress1(e.detail.value)} placeholder="Address 1" minlength={3} maxlength={240} required={true} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Address 2</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.address2} onIonChange={(e) => props.setAddress2(e.detail.value)} placeholder="Address 2" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">City</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.city} onIonChange={(e) => props.setCity(e.detail.value)} placeholder="City" minlength={2} maxlength={90} required={true} />
                </IonItem>
                <IonList>
                    <IonItem>
                        <IonLabel position="fixed">Country:</IonLabel>
                        <IonSelect interface="action-sheet" placeholder='Select Country' class="select-full-width" value={props.countryid} onIonChange={(e) => { props.setCountryid(e.detail.value); props.setStateid(null); getCountriesStates(e.detail.value); }}>
                            {countries.map((country: any) => {
                                return <IonSelectOption key={country.id} value={country.id}>{country.country}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonItem>
                </IonList>
                <IonList>
                    <IonItem>
                        <IonLabel position="fixed">State:</IonLabel>
                        <IonSelect interface="action-sheet" placeholder='Select State' class="select-full-width" value={props.stateid} onIonChange={(e) => { props.setStateid(e.detail.value) }}>
                            {states.map((state: any) => {
                                return <IonSelectOption key={state.id} value={state.id}>{state.state}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonItem>
                </IonList>
                <IonItem>
                    <IonLabel position="stacked">Zip Code</IonLabel>
                    <IonInput clearInput={true} type="text" value={props.zip} onIonChange={(e) => props.setZip(e.detail.value)} placeholder="Zip Code" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Cell Number</IonLabel>
                    <IonInput clearInput={true} type="number" value={props.phone} onIonChange={(e) => props.setPhone(e.detail.value)} placeholder="Cell Number" />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Work Number</IonLabel>
                    <IonInput clearInput={true} type="number" value={props.wphone} onIonChange={(e) => props.setWphone(e.detail.value)} placeholder="Work Number" />
                </IonItem>
                <IonGrid>
                    <IonRow class='ion-justify-content-center'>
                        <IonButton type="submit" fill="outline" onClick={() => {
                            if (!clicked && validate()) {
                                updateProfileInfo();
                            }
                        }} >
                            <IonIcon slot="start" icon={checkmarkOutline} />
                            &nbsp;Save
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    </>);
}

export default EditProfileModal;