import { useCallback, useState } from 'react';

import { IonGrid, IonRow, IonCol, IonIcon, IonTitle } from '@ionic/react';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';

function NumberPicker(props: any) {
    const [value, setValue] = useState(props.value);

    function addValue() {
        if (value < props.max) {
            return value + 1;
        } else { return value }
    }
    function minusValue() {
        if (value > props.min) {
            return value - 1;
        } else { return value }
    }

    return <IonGrid>
        <IonRow>
            <IonCol>
                <IonRow class="ion-justify-content-center">
                    <h4>{props.columnHeading}</h4>
                </IonRow>

                <IonRow class="ion-justify-content-center">
                    <IonIcon size="large" icon={addCircleOutline} onClick={() => {
                        setValue((prevValue: any) => { return prevValue < props.max ? prevValue + props.step : props.max });

                        props.setRsvpInfo((prevRsvpInfo: any) => { return { ...prevRsvpInfo, [props.columnHeading]: addValue() } });
                    }} />
                </IonRow>

                <IonRow class="ion-justify-content-center">
                    <h4>{value}</h4>
                </IonRow>

                <IonRow class="ion-justify-content-center">
                    <IonIcon size="large" icon={removeCircleOutline} onClick={() => {
                        setValue((prevValue: any) => { return prevValue > props.min ? prevValue - props.step : props.min });

                        props.setRsvpInfo((prevRsvpInfo: any) => { return { ...prevRsvpInfo, [props.columnHeading]: minusValue() } });
                    }} />
                </IonRow>
            </IonCol>
            {/* <IonCol>
                <IonRow class="ion-justify-content-center"><h4>WOMEN</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={addCircleOutline}/></IonRow>
                <IonRow class="ion-justify-content-center"><h4>99</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={removeCircleOutline}/></IonRow>
            </IonCol>
            <IonCol>
                <IonRow class="ion-justify-content-center"><h4>GIRLS</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={addCircleOutline}/></IonRow>
                <IonRow class="ion-justify-content-center"><h4>99</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={removeCircleOutline}/></IonRow>
            </IonCol>
            <IonCol>
                <IonRow class="ion-justify-content-center"><h4>BOYS</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={addCircleOutline}/></IonRow>
                <IonRow class="ion-justify-content-center"><h4>99</h4></IonRow>
                <IonRow class="ion-justify-content-center"><IonIcon size="large" icon={removeCircleOutline}/></IonRow>
            </IonCol> */}
        </IonRow>
    </IonGrid>
}

NumberPicker.defaultProps = {
    columnHeading: "Select",
    value: 0,
    max: 99,
    min: 0,
    step: 1
}

export default NumberPicker;