import { useState, useEffect } from 'react';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { closeOutline, videocamOutline } from 'ionicons/icons';
import './../Events.css';

function Report(props: any) {
    // const [props.reportData, setprops.reportData]: any = useState(props.reportprops.reportData);
    const [show, setShow] = useState(props.showReport);
    // const [props.attending, setprops.attending] = useState(props.props.attending);
    // const [props.declined, setprops.declined] = useState(props.props.declined);
    // const [props.thaalStats, setprops.thaalStats] = useState(props.props.thaalStats);

    // async function getReport() {
    //     props.setLoading(true);
    //     props.setLoadMessage('Fetching Report...')
    //     const url = `https://wennect.info/apps/events/getprops.reportData.php?CurrentLogin={"id":"${localStorage.getItem('username')}","access_ticket":"${localStorage.getItem('accessToken')}"}&EventID=${props.event}&commID=${localStorage.getItem('currentCommunityID')}`;
    //     console.log(url)
    //     try {
    //         const response = await fetch(url);
    //         const jprops.reportData = await response.json();
    //         console.log(jprops.reportData);
    //         if (jprops.reportData.constructor === Object && jprops.reportData.CODE && jprops.reportData.CODE === 'FAILED' && jprops.reportData.CODE !== 'NOprops.reportData') {
    //             props.setToastMessage(jprops.reportData.DESCRIPTION);
    //             props.setToastType('danger');
    //             props.setShowToast(true);
    //             dismiss();
    //         } else {
    //             if (jprops.reportData.CODE === 'NOprops.reportData') {
    //                 props.setToastMessage(jprops.reportData.DESCRIPTION);
    //                 props.setToastType('success');
    //                 props.setShowToast(true);
    //                 dismiss();
    //             } else {
    //                 sessionStorage.setItem('report', JSON.stringify(jprops.reportData));
    //                 console.log(jprops.reportData.event_title);
    //                 setprops.reportData(jprops.reportData);
    //                 let attend: any = Object.entries(jprops.reportData.props.attending)
    //                 let decline: any = Object.entries(jprops.reportData.props.declined)
    //                 let thaal: any = Object.entries(jprops.reportData.thaal_stats)
    //                 setprops.attending(attend);
    //                 setprops.declined(decline);
    //                 setprops.thaalStats(thaal);
    //                 console.log('Displaying Report Modal...');
    //                 setShow(true);
    //             }
    //         }
    //     } catch (error: any) {
    //         console.log(error)
    //         props.setToastMessage(error.message);
    //         props.setToastType('danger');
    //         props.setShowToast(true);
    //         dismiss();
    //     } props.setLoading(false);
    // }

    function dismiss() {
        props.setShowReport(false);
        console.log(show);
        setShow(false);
        sessionStorage.removeItem('report');
    }

    return <>
        <IonModal isOpen={props.showReport} onDidDismiss={() => dismiss()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Check-In</IonTitle>
                    <IonButtons onClick={() => dismiss()} slot="end">
                        <IonButton onClick={() => dismiss()}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow class="ion-justify-content-center">
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    {props.reportData.event_title}
                                </IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonRow>
                    {props.reportData && <>
                        <IonCard>
                            <IonCardContent>
                                <p>
                                    Pending RSVPs: {props.reportData.UserCountWithoutRSVP}<br />
                                    RSVPs: {props.reportData.RSVPCount}<br />
                                    Thaal Count: {props.reportData.RSVPThaalCount}<br />
                                </p><br />
                                <p>
                                    <div className="listcaption"><b>Attending:</b></div>
                                    <ul>
                                        {props.attending.map(([key, value]: any) => {
                                            return <li key={key}>{key}: {value}</li>;
                                        })}
                                    </ul>
                                </p><br />
                                <p>
                                    <div className="listcaption"><b>Declined:</b></div>
                                    <ul>
                                        {props.declined.map(([key, value]: any) => {
                                            return <li key={key}>{key}: {value}</li>;
                                        })}
                                    </ul>
                                </p><br />
                                <p>
                                    Checked In: {props.reportData.CheckInCount}<br />
                                </p><br /><br />
                                <p>
                                    <div className="listcaption"><b>Thaal Stats:</b></div>
                                    <ul>
                                        {props.thaalStats.map(([key, value]: any) => {
                                            return <li key={key}>{key}: {value}</li>;
                                        })}
                                    </ul>
                                </p>
                            </IonCardContent>
                        </IonCard>
                    </>}
                </IonGrid>
            </IonContent>
        </IonModal>
    </>;
}

export default Report;