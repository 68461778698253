import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonIcon, IonList, IonItem, IonLabel, IonGrid, IonCol, IonRow } from '@ionic/react';
import { closeOutline, shieldHalfOutline } from 'ionicons/icons';


function GetCommunity(props: any) {    
    try {
        const data: any = window.localStorage.getItem('communityDetails');
        // console.log(JSON.parse(data));
        const communityDetails = JSON.parse(data).communities;
        
        return (
            <IonModal isOpen={props.isOpen} onDidDismiss={() => props.setIsOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Select Community</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => props.setIsOpen(false)}>
                                <IonIcon icon={closeOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonList inset={true}>
                        {
                            communityDetails.map((community: any) => {
                                return (
                                    <IonItem button={true} key={community.ID} onClick={() => {
                                        console.log(community);
                                        window.localStorage.setItem('isPrivateEventAllowed', JSON.stringify(community));
                                        window.localStorage.setItem('isEventAdmin', community.iAmEventsAdmin)
                                        window.localStorage.setItem('isCommunityAdmin', community.iAmAdmin);
                                        window.localStorage.setItem('currentCommunityID', community.ID);
                                        window.localStorage.setItem('currentCommunityDetails', JSON.stringify(community));
                                        window.localStorage.setItem('currentCommunityName', community.c_name);
                                        window.sessionStorage.clear()
                                        window.localStorage.removeItem('family_info');
                                        props.checkFetching();
                                        props.setRefresh(false);
                                        props.setRefresh(true);
                                        props.setIsOpen(false);
                                    }}>
                                        <IonGrid>
                                            <IonRow class="ion-justify-content-between">
                                                <IonCol size="auto" style={{ fontSize: 20 }}>
                                                    {community.c_name}{community.iAmAdmin === 'true' && <>&nbsp;<IonIcon icon={shieldHalfOutline} /></>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol style={{ fontSize: 12 }}>{community.city}</IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>
                                );
                            })
                        }
                    </IonList>
                </IonContent>
            </IonModal>
        );
    } catch (e: any) {
        console.log(e.message);
        return <></>;
    }
}

export default GetCommunity;