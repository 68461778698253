import { IonLoading, useIonRouter, IonList, IonItem, IonInput, IonButton, IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel } from '@ionic/react';
import { useState } from 'react';
import globalconsts from '../../constants';

function Signup(props: any) {
    let clicked = false;
    const router = useIonRouter();
    
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [toastType, setToastType] = useState('danger');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(false);

    async function checkEmail() {
        setToastType('danger')
        const url = `https://wennect.info/api/emailexists.php?e_mail=${email}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                return true;
            } else if (data.constructor === Object && data.CODE && data.CODE === 'FAILED') {
                setToastMessage('Email already in use');
                setShowToast(true);
                return false;
            } else {
                setToastMessage('Unexpected Error: ' + data);
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function checkUsername() {
        setToastType('danger')
        const url = `https://wennect.info/api/unameexists.php?id=${localStorage.getItem('username')}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                return checkEmail();
            } else if (data.constructor === Object && data.CODE && data.CODE === 'FAILED') {
                setToastMessage('Username already exists');
                setShowToast(true);
                return false;
            } else {
                setToastMessage('Unexpected Error: ' + data);
                setShowToast(true);
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    function validate() {
        setToastType('danger');
        if (username.length < 5 || username.length > 90) {
            if (username.length === 0)
                setToastMessage('Username is required');
            else
                setToastMessage('Username must be between 5 and 90 characters');
            setShowToast(true);
        } else if (email === null) {
            setToastMessage('Email is required');
            setShowToast(true);
        } else if (email.length < 3 || email.length > 254) {
            setToastMessage('Email must be between 3 and 254 characters');
            setShowToast(true);
        } else if (!email.includes('@')) {
            setToastMessage("Please Include an '@' in the Email");
            setShowToast(true);
        } else if (fname === null || fname.length < 3 || fname.length > 90) {
            if (fname === null || fname.length === 0)
                setToastMessage("Please Enter a First Name");
            else
                setToastMessage("First Name must be between 3 and 90 characters");
            setShowToast(true);
        } else if (lname.length > 0 && (lname.length < 3 || lname.length > 90)) {
            setToastMessage("Last Name must be between 3 and 90 characters");
            setShowToast(true);
        } else if (password.length < 5 || password.length > 16) {
            if (password.length === 0)
                setToastMessage("Please Enter a Password");
            else
                setToastMessage("Password must be between 5 and 16 characters");
            setShowToast(true);
        } else if (password !== confirmPassword) {
            setToastMessage("Passwords must match");
            setShowToast(true);
        }else if (!checkUsername) {
            return false;
        } else {
            return true;
        } return false;
    }

    async function submitData() {
        console.log('submitting');
        clicked = false;
        setLoading(true);

        let uname = localStorage.getItem('username');
        if (uname === null) {
            uname = '';
        } const url = `https://wennect.info/api/createUser.php?usrObj={"uname":"${encodeURI(escape(uname))}","f_name":"${encodeURI(escape(fname))}","l_name":"${encodeURI(escape(lname))}","ad1":"","":"","city":"","stateid":"","zp":"","country":"","pwd":"${encodeURI(escape(password))}","cphone":"","wphone":"","email":"${encodeURI(escape(email))}","e_mail2":"","precom":""}`

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === 'SUCCESS') {
                setToastType('success');
                setToastMessage('Account Created');
                setShowToast(true);
                router.push("/" + globalconsts.base_app_URI + "events");
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setToastType('danger');
                setToastMessage('Unexpected Error: ' + data.DESCRIPTION);
                setShowToast(true);
            } else {
                setToastType('danger');
                setToastMessage('Unexpected Error: ' + data);
                setShowToast(true);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        clicked = true;
    }

    return (<>
        <IonCard>
            <IonLoading isOpen={loading} message='Creating Account...' spinner="circles" />
            <IonCardHeader>
                <IonCardTitle>Sign Up</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList inset={false}>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Username</IonLabel>
                            <IonInput type="text" placeholder="Username" minlength={3} onIonChange={(e: any) => setUsername(e.target.value.trim())} required={true} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput type="email" placeholder="Email" minlength={3} onIonChange={(e: any) => setEmail(e.target.value.trim())} required={true} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">First Name</IonLabel>
                            <IonInput type="text" placeholder="First Name" minlength={3} onIonChange={(e: any) => setFname(e.target.value.trim())} required={true} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Last Name</IonLabel>
                            <IonInput type="text" placeholder="Lastname" minlength={3} onIonChange={(e: any) => setLname(e.target.value.trim())} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Password</IonLabel>
                            <IonInput type="password" placeholder="Password" minlength={3} onIonChange={(e: any) => setPassword(e.target.value)} required={true} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Confirm Password</IonLabel>
                            <IonInput type="password" placeholder="Confirm Password" minlength={3} onIonChange={(e: any) => setConfirmPassword(e.target.value.trim())} required={true} />
                        </IonCardContent>
                    </IonItem>
                </IonList>

                <IonButton type="submit" fill="outline" onClick={() => {
                    if (!clicked && validate()) {
                        submitData();
                    }
                }}>Sign Up</IonButton>
                
                <IonToast
                    position="middle"
                    color="danger"
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMessage}
                    duration={1800}
                />
            </IonCardContent>
        </IonCard >
    </>);
}

export default Signup;