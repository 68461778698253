import { Capacitor } from '@capacitor/core';
import { IonLoading, IonList, IonItem, IonInput, IonButton, IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel } from '@ionic/react';
import { useState } from 'react';


function Login(props: any) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');

    async function fetchData() {
        if (username.trim() === '' || password.trim() === '') {
            setShowToast(true);
            setToastMessage('Please fill in all fields!');
            return;
        }

        const url = `https://wennect.info/api/login.php?loginObj={"id":"${username}","pwd":"${password}","remember":"true"}&verObj={"version":"2.1.2","build":"212","relType":"Prod","platform":"${Capacitor.getPlatform()}"}`;
        setLoading(true);
        setLoadMessage('Logging in...');
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.constructor === Object && data.CODE && data.CODE === "SUCCESS") {
                window.localStorage.setItem('isLoggedIn', "1");
                window.localStorage.setItem('accessToken', data.DESCRIPTION);
                window.localStorage.setItem('username', username);
                props.setIsLoggedIn('1');
                
                window.location.reload();
            } else if (data.constructor === Object && data.DESCRIPTION) {
                setShowToast(true);
                setToastMessage(data.DESCRIPTION)
            } else {
                setShowToast(true);
                setToastMessage('Something went wrong!')
            }
        } catch (e: any) {
            setShowToast(true);
            setToastMessage(e.message);
        } setLoading(false);
    }

    return (
        <IonCard>
            <IonLoading isOpen={loading} message={loadMessage} spinner="circles" />
            <IonCardHeader>
                <IonCardTitle>Login</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList inset={false}>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Username</IonLabel>
                            <IonInput type="text" placeholder="Username" minlength={3} onIonChange={(e: any) => setUsername(e.target.value.trim())} required={true} />
                        </IonCardContent>
                    </IonItem>
                    <IonItem>
                        <IonCardContent>
                            <IonLabel position="floating">Password</IonLabel>
                            <IonInput type="password" placeholder="Password" minlength={3} onIonChange={(e: any) => setPassword(e.target.value)} required={true} />
                        </IonCardContent>
                    </IonItem>
                </IonList>

                <IonButton type="submit" fill="outline" onClick={fetchData}>Login</IonButton>
                <IonToast
                    position="middle"
                    color="danger"
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMessage}
                    duration={1800}
                />
            </IonCardContent>
        </IonCard>
    );
}

export default Login;